// import ArrangedCards from "mobile/course/wrappers/formals/ArrangedCards.react";
// import FeedCards from "mobile/course/wrappers/formals/FeedCards.react";
// import SimpleListing from "mobile/course/wrappers/formals/SimpleListing.react";
// import StackedImages from "mobile/course/wrappers/formals/StackedImages.react";
// import TinderCards from "mobile/course/wrappers/formals/TinderCards.react";

export const WrapperList = {
    none: {
        wrapperType: "formal",
    },
    stacked_cards: {
        wrapperType: "formal",
    },
    stacked_images: {
        wrapperType: "formal",
    },
    swipeable_cards: {
        wrapperType: "formal",
    },
    feed_cards: {
        wrapperType: "formal",
    },
    background_listing: {
        wrapperType: "formal",
    },
    road_to_success: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            {"sequence":0,"top":58,"left":63},
            {"sequence":1,"top":58,"left":79},
            {"sequence":2,"top":74.50825231743161,"left":91.72690763052209},
            {"sequence":3,"top":81,"left":74},{"sequence":4,"top":89,"left":45},
            {"sequence":5,"top":79,"left":38},{"sequence":6,"top":66,"left":44},
            {"sequence":7,"top":66,"left":17},{"sequence":8,"top":54,"left":20},
            {"sequence":9,"top":47,"left":38},{"sequence":10,"top":48,"left":55},
            {"sequence":11,"top":39,"left":65}]
    },
    island_adventure: {
        wrapperType: "standard",
        zoomable: true,
        positions: [{"sequence":0,"top":26,"left":46},
        {"sequence":1,"top":29,"left":32},
        {"sequence":2,"top":35,"left":20},
        {"sequence":3,"top":50,"left":12},
        {"sequence":4,"top":61,"left":26},
        {"sequence":5,"top":54,"left":42},
        {"sequence":6,"top":67,"left":54},
        {"sequence":7,"top":72,"left":79},
        {"sequence":8,"top":56,"left":94},
        {"sequence":9,"top":54,"left":79},
        {"sequence":10,"top":47,"left":60},
        {"sequence":11,"top":16.13328313253012,"left":63.85542168674698}]
    },
    world_map: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 68, left: 82 },
            { sequence: 1, top: 19, left: 74 },
            { sequence: 2, top: 33, left: 80 },
            { sequence: 3, top: 46.6152108433735, left: 74.77911646586345 },
            { sequence: 4, top: 44.92846385542168, left: 67.63052208835342 },
            { sequence: 5, top: 31.79593373493976, left: 64.81927710843374 },
            { sequence: 6, top: 66, left: 54 },
            { sequence: 7, top: 60.2296686746988, left: 36.06425702811245 },
            { sequence: 8, top: 74.6875, left: 30.44176706827309 },
            { sequence: 9, top: 38.422439759036145, left: 24.016064257028113 },
            { sequence: 10, top: 27, left: 21 },
            { sequence: 11, top: 19.98870481927711, left: 11.405622489959839 },
            { sequence: 12, top: 12, left: 39 },
        ],
    },
    warehouse: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 94, left: 49 },
            { sequence: 1, top: 89, left: 71 },
            { sequence: 2, top: 60, left: 69 },
            { sequence: 3, top: 51, left: 85 },
            { sequence: 4, top: 29.988704819277107, left: 88.67469879518072 },
            { sequence: 5, top: 7.940512048192772, left: 88.67469879518072 },
            { sequence: 6, top: 3.6031626506024095, left: 63.85542168674698 },
            { sequence: 7, top: 7.217620481927711, left: 30.44176706827309 },
            { sequence: 8, top: 20.71159638554217, left: 47.630522088353416 },
            { sequence: 9, top: 42.51882530120482, left: 34.61847389558233 },
            { sequence: 10, top: 46.253765060240966, left: 21.365461847389557 },
            { sequence: 11, top: 40.832078313253014, left: 5.220883534136546 },
            { sequence: 12, top: 69, left: 24 },
        ],
    },
    usa: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 79, left: 83 },
            { sequence: 1, top: 67, left: 78 },
            { sequence: 2, top: 49, left: 77 },
            { sequence: 3, top: 30, left: 87 },
            { sequence: 4, top: 11.554969879518072, left: 91.4859437751004 },
            { sequence: 5, top: 33.60316265060241, left: 70.28112449799197 },
            { sequence: 6, top: 19.868222891566266, left: 54.859437751004016 },
            { sequence: 7, top: 43.482680722891565, left: 56.70682730923695 },
            { sequence: 8, top: 44.32605421686747, left: 40 },
            { sequence: 9, top: 18, left: 34 },
            { sequence: 10, top: 11.916415662650602, left: 18.393574297188756 },
            { sequence: 11, top: 44, left: 15 },
            { sequence: 12, top: 73, left: 46 },
        ],
    },
    university: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 56, left: 6 },
            { sequence: 1, top: 67, left: 21 },
            { sequence: 2, top: 52, left: 42 },
            { sequence: 3, top: 27, left: 24 },
            { sequence: 4, top: 11.916415662650602, left: 27.068273092369477 },
            { sequence: 5, top: 21, left: 36 },
            { sequence: 6, top: 28.542921686746986, left: 48.11244979919679 },
            { sequence: 7, top: 12, left: 63 },
            { sequence: 8, top: 6, left: 81 },
            { sequence: 9, top: 24.446536144578314, left: 78.714859437751 },
            { sequence: 10, top: 47, left: 64 },
            { sequence: 11, top: 61.91641566265061, left: 56.06425702811245 },
            { sequence: 12, top: 67, left: 73 },
        ],
    },
    underword: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 88, left: 15 },
            { sequence: 1, top: 83, left: 28 },
            { sequence: 2, top: 46, left: 35 },
            { sequence: 3, top: 15, left: 44 },
            { sequence: 4, top: 25, left: 33 },
            { sequence: 5, top: 29, left: 7 },
            { sequence: 6, top: 45, left: 21 },
            { sequence: 7, top: 59, left: 41 },
            { sequence: 8, top: 63.00075301204819, left: 53.333333333333336 },
            { sequence: 9, top: 78, left: 71 },
            { sequence: 10, top: 57, left: 63 },
            { sequence: 11, top: 43, left: 71 },
            { sequence: 12, top: 20, left: 79 },
        ],
    },
    treasure_island: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 74, left: 7 },
            { sequence: 1, top: 58, left: 14 },
            { sequence: 2, top: 52, left: 26 },
            { sequence: 3, top: 36, left: 14 },
            { sequence: 4, top: 16, left: 30 },
            { sequence: 5, top: 19, left: 42 },
            { sequence: 6, top: 32.7597891566265, left: 51.566265060240966 },
            { sequence: 7, top: 32.51882530120482, left: 63.53413654618474 },
            { sequence: 8, top: 31, left: 83 },
            { sequence: 9, top: 43.723644578313255, left: 67.8714859437751 },
            { sequence: 10, top: 61.554969879518076, left: 64.17670682730923 },
            { sequence: 11, top: 74.44653614457832, left: 66.50602409638554 },
            { sequence: 12, top: 77, left: 49 },
        ],
    },
    treasure_hunt: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 94, left: 76 },
            { sequence: 1, top: 78, left: 71 },
            { sequence: 2, top: 61, left: 58 },
            { sequence: 3, top: 48, left: 76 },
            { sequence: 4, top: 33.60316265060241, left: 85.94377510040161 },
            { sequence: 5, top: 41, left: 62 },
            { sequence: 6, top: 35.16942771084338, left: 44.096385542168676 },
            { sequence: 7, top: 11, left: 52 },
            { sequence: 8, top: 22, left: 34 },
            { sequence: 9, top: 7.6995481927710845, left: 20.240963855421686 },
            { sequence: 10, top: 36, left: 9 },
            { sequence: 11, top: 51, left: 31 },
            { sequence: 12, top: 82, left: 26 },
        ],
    },

    supermarkets: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 38.3019578313253, left: 98.3132530120482 },
            { sequence: 1, top: 29, left: 90 },
            { sequence: 2, top: 13, left: 74 },
            { sequence: 3, top: 23, left: 66 },
            { sequence: 4, top: 13.24171686746988, left: 32.7710843373494 },
            { sequence: 5, top: 25, left: 42 },
            { sequence: 6, top: 28, left: 8 },
            { sequence: 7, top: 51.67545180722891, left: 10.040160642570282 },
            { sequence: 8, top: 49.74774096385542, left: 27.469879518072286 },
            { sequence: 9, top: 42.88027108433735, left: 40.08032128514056 },
            { sequence: 10, top: 68, left: 48 },
            { sequence: 11, top: 71, left: 67 },
            { sequence: 12, top: 58, left: 91 },
        ],
    },
    suburbs: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 63.00075301204819, left: 95.26104417670683 },
            { sequence: 1, top: 52, left: 82 },
            { sequence: 2, top: 36, left: 74 },
            { sequence: 3, top: 17.21762048192771, left: 77.34939759036145 },
            { sequence: 4, top: 21, left: 50 },
            { sequence: 5, top: 12, left: 27 },
            { sequence: 6, top: 23, left: 9 },
            { sequence: 7, top: 44.6875, left: 9.317269076305221 },
            { sequence: 8, top: 56.97665662650603, left: 21.365461847389557 },
            { sequence: 9, top: 47.69954819277108, left: 43.373493975903614 },
            { sequence: 10, top: 59.50677710843374, left: 59.27710843373494 },
            { sequence: 11, top: 81, left: 54 },
            { sequence: 12, top: 93, left: 61 },
        ],
    },
    stadium: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 23, left: 44 },
            { sequence: 1, top: 31, left: 44 },
            { sequence: 2, top: 36.13328313253012, left: 57.26907630522088 },
            { sequence: 3, top: 22, left: 67 },
            { sequence: 4, top: 29, left: 82 },
            { sequence: 5, top: 43.964608433734945, left: 87.30923694779116 },
            { sequence: 6, top: 60, left: 75 },
            { sequence: 7, top: 55, left: 42 },
            { sequence: 8, top: 75, left: 53 },
            { sequence: 9, top: 41, left: 6 },
            { sequence: 10, top: 25, left: 12 },
            { sequence: 11, top: 29, left: 24 },
            { sequence: 12, top: 32.03689759036145, left: 33.89558232931727 },
        ],
    },
    stadium2: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 92, left: 34 },
            { sequence: 1, top: 83, left: 44 },
            { sequence: 2, top: 80, left: 22 },
            { sequence: 3, top: 79, left: 6 },
            { sequence: 4, top: 63, left: 6 },
            { sequence: 5, top: 37.33810240963855, left: 14.538152610441768 },
            { sequence: 6, top: 62.63930722891566, left: 26.104417670682732 },
            { sequence: 7, top: 56, left: 39 },
            { sequence: 8, top: 56, left: 63 },
            { sequence: 9, top: 38, left: 79 },
            { sequence: 10, top: 55, left: 92 },
            { sequence: 11, top: 73, left: 90 },
            { sequence: 12, top: 82, left: 56 },
        ],
    },
    rome: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 88.02336536649707, left: 7.710843373493977 },
            { sequence: 1, top: 78.13453928773319, left: 13.413654618473897 },
            { sequence: 2, top: 63, left: 35 },
            { sequence: 3, top: 52, left: 42 },
            { sequence: 4, top: 41, left: 25 },
            { sequence: 5, top: 17, left: 12 },
            { sequence: 6, top: 32.18767665347654, left: 32.69076305220884 },
            { sequence: 7, top: 19.16336913510458, left: 48.35341365461847 },
            { sequence: 8, top: 25, left: 69 },
            { sequence: 9, top: 19, left: 93 },
            { sequence: 10, top: 46.65912945166761, left: 84.73895582329317 },
            { sequence: 11, top: 67.4015451290748, left: 69.7991967871486 },
            { sequence: 12, top: 59, left: 48 },
        ],
    },
    office: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 13, left: 54 },
            { sequence: 1, top: 16, left: 62 },
            { sequence: 2, top: 22, left: 74 },
            { sequence: 3, top: 40, left: 84 },
            { sequence: 4, top: 67, left: 92 },
            { sequence: 5, top: 51, left: 71 },
            { sequence: 6, top: 59, left: 30 },
            { sequence: 7, top: 69, left: 12 },
            { sequence: 8, top: 34, left: 37 },
            { sequence: 9, top: 17, left: 22 },
            { sequence: 10, top: 41, left: 16 },
            { sequence: 11, top: 58, left: 50 },
            { sequence: 12, top: 23.121234939759034, left: 46.907630522088354 },
        ],
    },
    norse_village: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 77.82003012048193, left: 98.63453815261045 },
            { sequence: 1, top: 76.3742469879518, left: 89.1566265060241 },
            { sequence: 2, top: 63.241716867469876, left: 66.26506024096386 },
            { sequence: 3, top: 44.56701807228916, left: 68.35341365461848 },
            { sequence: 4, top: 30, left: 89 },
            { sequence: 5, top: 14, left: 80 },
            { sequence: 6, top: 21, left: 64 },
            { sequence: 7, top: 55, left: 37 },
            { sequence: 8, top: 31, left: 12 },
            { sequence: 9, top: 30, left: 35 },
            { sequence: 10, top: 10, left: 29 },
            { sequence: 11, top: 16, left: 56 },
            { sequence: 12, top: 6, left: 52 },
        ],
    },
    movie_studio: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 38, left: 88 },
            { sequence: 1, top: 45, left: 84 },
            { sequence: 2, top: 50, left: 94 },
            { sequence: 3, top: 64, left: 91 },
            { sequence: 4, top: 70, left: 77 },
            { sequence: 5, top: 52.88027108433735, left: 67.8714859437751 },
            { sequence: 6, top: 39, left: 58 },
            { sequence: 7, top: 21, left: 49 },
            { sequence: 8, top: 20, left: 21 },
            { sequence: 9, top: 41.9164156626506, left: 12.690763052208837 },
            { sequence: 10, top: 60.711596385542165, left: 21.12449799196787 },
            { sequence: 11, top: 69.26581325301204, left: 35.1004016064257 },
            { sequence: 12, top: 51, left: 46 },
        ],
    },
    military: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 94, left: 57 },
            { sequence: 1, top: 73, left: 62 },
            { sequence: 2, top: 66.01280120481928, left: 77.59036144578313 },
            { sequence: 3, top: 42.63930722891566, left: 94.61847389558233 },
            { sequence: 4, top: 20.229668674698793, left: 95.42168674698796 },
            { sequence: 5, top: 18.18147590361446, left: 70.36144578313252 },
            { sequence: 6, top: 7.820030120481928, left: 46.666666666666664 },
            { sequence: 7, top: 22.27786144578313, left: 40.72289156626506 },
            { sequence: 8, top: 28.181475903614455, left: 18.152610441767067 },
            { sequence: 9, top: 40.10918674698795, left: 29.799196787148595 },
            { sequence: 10, top: 66.01280120481928, left: 35.1004016064257 },
            { sequence: 11, top: 53.241716867469876, left: 16.78714859437751 },
            { sequence: 12, top: 86, left: 35 },
        ],
    },
    mall: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 45.5308734939759, left: 1.0441767068273093 },
            { sequence: 1, top: 51, left: 16 },
            { sequence: 2, top: 39, left: 21 },
            { sequence: 3, top: 17, left: 15 },
            { sequence: 4, top: 6, left: 32 },
            { sequence: 5, top: 6, left: 92 },
            { sequence: 6, top: 29, left: 94 },
            { sequence: 7, top: 49, left: 67 },
            { sequence: 8, top: 31, left: 50 },
            { sequence: 9, top: 38, left: 36 },
            { sequence: 10, top: 56, left: 87 },
            { sequence: 11, top: 69, left: 59 },
            { sequence: 12, top: 65.04894578313252, left: 6.827309236947792 },
        ],
    },
    library: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 49.38629518072289, left: 8.674698795180722 },
            { sequence: 1, top: 41, left: 14 },
            { sequence: 2, top: 52, left: 50 },
            { sequence: 3, top: 37, left: 32 },
            { sequence: 4, top: 16.856174698795183, left: 32.85140562248996 },
            { sequence: 5, top: 10, left: 51 },
            { sequence: 6, top: 30, left: 62 },
            { sequence: 7, top: 31.79593373493976, left: 87.38955823293173 },
            { sequence: 8, top: 50.832078313253014, left: 85.54216867469879 },
            { sequence: 9, top: 66.61521084337349, left: 80.16064257028113 },
            { sequence: 10, top: 91.0730421686747, left: 55.58232931726907 },
            { sequence: 11, top: 73.8441265060241, left: 34.13654618473896 },
            { sequence: 12, top: 61.67545180722892, left: 15.421686746987953 },
        ],
    },
    hotspot: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 10, left: 53 },
            { sequence: 1, top: 24, left: 63 },
            { sequence: 2, top: 33, left: 85 },
            { sequence: 3, top: 52, left: 85 },
            { sequence: 4, top: 59, left: 68 },
            { sequence: 5, top: 75, left: 43 },
            { sequence: 6, top: 60, left: 30 },
            { sequence: 7, top: 38, left: 50 },
            { sequence: 8, top: 32, left: 8 },
            { sequence: 9, top: 37, left: 21 },
            { sequence: 10, top: 33, left: 37 },
            { sequence: 11, top: 36, left: 61 },
            { sequence: 12, top: 23, left: 51 },
        ],
    },
    egypt: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 94, left: 19 },
            { sequence: 1, top: 81.91641566265059, left: 30.602409638554217 },
            { sequence: 2, top: 53.241716867469876, left: 29.95983935742972 },
            { sequence: 3, top: 38.542921686746986, left: 25.220883534136547 },
            { sequence: 4, top: 31.434487951807228, left: 3.5341365461847385 },
            { sequence: 5, top: 6, left: 20 },
            { sequence: 6, top: 18.18147590361446, left: 31.40562248995984 },
            { sequence: 7, top: 28.422439759036145, left: 46.666666666666664 },
            { sequence: 8, top: 37.09713855421687, left: 65.3012048192771 },
            { sequence: 9, top: 13, left: 83 },
            { sequence: 10, top: 35, left: 92 },
            { sequence: 11, top: 64.80798192771084, left: 81.76706827309236 },
            { sequence: 12, top: 86.13328313253012, left: 69.5582329317269 },
        ],
    },
    clubs: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 58.542921686746986, left: 97.91164658634538 },
            { sequence: 1, top: 52, left: 88 },
            { sequence: 2, top: 40, left: 81 },
            { sequence: 3, top: 25, left: 78 },
            { sequence: 4, top: 31, left: 61 },
            { sequence: 5, top: 21, left: 47 },
            { sequence: 6, top: 37.09713855421687, left: 39.196787148594375 },
            { sequence: 7, top: 54.44653614457832, left: 45.70281124497992 },
            { sequence: 8, top: 33, left: 20 },
            { sequence: 9, top: 57, left: 10 },
            { sequence: 10, top: 73.36219879518072, left: 41.606425702811244 },
            { sequence: 11, top: 65.65135542168676, left: 67.46987951807229 },
            { sequence: 12, top: 63, left: 82 },
        ],
    },
    cityscape_standard: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 78.42243975903614, left: 1.3654618473895583 },
            { sequence: 1, top: 67, left: 15 },
            { sequence: 2, top: 54, left: 33 },
            { sequence: 3, top: 41, left: 50 },
            { sequence: 4, top: 16.012801204819276, left: 21.44578313253012 },
            { sequence: 5, top: 12, left: 41 },
            { sequence: 6, top: 12, left: 75 },
            { sequence: 7, top: 25.289909638554214, left: 93.33333333333333 },
            { sequence: 8, top: 36.97665662650602, left: 74.77911646586345 },
            { sequence: 9, top: 57, left: 86 },
            { sequence: 10, top: 77, left: 88 },
            { sequence: 11, top: 93, left: 69 },
            { sequence: 12, top: 91, left: 40 },
        ],
    },
    castle: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 93, left: 31 },
            { sequence: 1, top: 86, left: 35 },
            { sequence: 2, top: 62, left: 38 },
            { sequence: 3, top: 59, left: 23 },
            { sequence: 4, top: 43, left: 7 },
            { sequence: 5, top: 18, left: 10 },
            { sequence: 6, top: 12, left: 41 },
            { sequence: 7, top: 6, left: 60 },
            { sequence: 8, top: 15, left: 78 },
            { sequence: 9, top: 31, left: 91 },
            { sequence: 10, top: 62, left: 88 },
            { sequence: 11, top: 75, left: 72 },
            { sequence: 12, top: 71, left: 57 },
        ],
    },
    casino: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 42.51882530120482, left: 98.39357429718876 },
            { sequence: 1, top: 53.723644578313255, left: 90.4417670682731 },
            { sequence: 2, top: 29, left: 80 },
            { sequence: 3, top: 47.69954819277108, left: 65.14056224899598 },
            { sequence: 4, top: 74, left: 57 },
            { sequence: 5, top: 78, left: 29 },
            { sequence: 6, top: 49, left: 49 },
            { sequence: 7, top: 56.97665662650603, left: 20.160642570281123 },
            { sequence: 8, top: 45.5308734939759, left: 3.614457831325301 },
            { sequence: 9, top: 32.39834337349398, left: 19.437751004016064 },
            { sequence: 10, top: 31.193524096385545, left: 36.94779116465863 },
            { sequence: 11, top: 10.832078313253012, left: 45.30120481927711 },
            { sequence: 12, top: 16.615210843373493, left: 70.68273092369478 },
        ],
    },
    carnival_standard: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 94, left: 88 },
            { sequence: 1, top: 89.26581325301206, left: 74.13654618473896 },
            { sequence: 2, top: 86.3742469879518, left: 58.23293172690763 },
            { sequence: 3, top: 81.43448795180723, left: 34.29718875502008 },
            { sequence: 4, top: 72.03689759036145, left: 16.224899598393574 },
            { sequence: 5, top: 63.12123493975904, left: 3.8554216867469884 },
            { sequence: 6, top: 44.6875, left: 5.622489959839357 },
            { sequence: 7, top: 52.27786144578314, left: 16.224899598393574 },
            { sequence: 8, top: 10.591114457831326, left: 25.220883534136547 },
            { sequence: 9, top: 26, left: 40 },
            { sequence: 10, top: 23.96460843373494, left: 52.77108433734939 },
            { sequence: 11, top: 48, left: 78 },
            { sequence: 12, top: 70.35015060240963, left: 88.43373493975903 },
        ],
    },
    career_path: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 94, left: 73 },
            { sequence: 1, top: 90.71159638554217, left: 66.66666666666666 },
            { sequence: 2, top: 82, left: 64 },
            { sequence: 3, top: 73.36219879518072, left: 66.50602409638554 },
            { sequence: 4, top: 65, left: 71 },
            { sequence: 5, top: 60.350150602409634, left: 62.48995983935743 },
            { sequence: 6, top: 53.96460843373494, left: 55.7429718875502 },
            { sequence: 7, top: 53, left: 50 },
            { sequence: 8, top: 63.00075301204819, left: 51.16465863453815 },
            { sequence: 9, top: 71.07304216867469, left: 45.78313253012048 },
            { sequence: 10, top: 76.13328313253011, left: 29.95983935742972 },
            { sequence: 11, top: 81.43448795180723, left: 15.180722891566264 },
            { sequence: 12, top: 95.04894578313254, left: 18.152610441767067 },
        ],
    },
    campsite: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 20.350150602409638, left: 42.168674698795186 },
            { sequence: 1, top: 26, left: 56 },
            { sequence: 2, top: 29, left: 70 },
            { sequence: 3, top: 54, left: 75 },
            { sequence: 4, top: 70, left: 75 },
            { sequence: 5, top: 88, left: 56 },
            { sequence: 6, top: 61, left: 56 },
            { sequence: 7, top: 69, left: 36 },
            { sequence: 8, top: 68, left: 18 },
            { sequence: 9, top: 44, left: 13 },
            { sequence: 10, top: 47, left: 31 },
            { sequence: 11, top: 37.57906626506024, left: 48.91566265060241 },
            { sequence: 12, top: 29.265813253012045, left: 43.53413654618474 },
        ],
    },
    bookstore: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 20.350150602409638, left: 42.168674698795186 },
            { sequence: 1, top: 26, left: 56 },
            { sequence: 2, top: 29, left: 70 },
            { sequence: 3, top: 54, left: 75 },
            { sequence: 4, top: 70, left: 75 },
            { sequence: 5, top: 88, left: 56 },
            { sequence: 6, top: 61, left: 56 },
            { sequence: 7, top: 69, left: 36 },
            { sequence: 8, top: 68, left: 18 },
            { sequence: 9, top: 44, left: 13 },
            { sequence: 10, top: 47, left: 31 },
            { sequence: 11, top: 37.57906626506024, left: 48.91566265060241 },
            { sequence: 12, top: 29.265813253012045, left: 43.53413654618474 },
        ],
    },
    boathouse: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 94, left: 45 },
            { sequence: 1, top: 93, left: 36 },
            { sequence: 2, top: 82.67354702625335, left: 19.598393574297187 },
            { sequence: 3, top: 70.37929865531657, left: 3.775100401606426 },
            { sequence: 4, top: 56.27707258277148, left: 1.4457831325301205 },
            { sequence: 5, top: 39.16155034087913, left: 2.9718875502008033 },
            { sequence: 6, top: 40, left: 27 },
            { sequence: 7, top: 24, left: 64 },
            { sequence: 8, top: 32, left: 82 },
            { sequence: 9, top: 39, left: 60 },
            { sequence: 10, top: 27.228897510264037, left: 20.401606425702813 },
            { sequence: 11, top: 17.706881615126747, left: 35.34136546184739 },
            { sequence: 12, top: 55, left: 63 },
        ],
    },
    bathhouse: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 88.06099397590361, left: 98.15261044176707 },
            { sequence: 1, top: 93.72364457831324, left: 75.90361445783132 },
            { sequence: 2, top: 93, left: 52 },
            { sequence: 3, top: 40.71159638554217, left: 56.94779116465863 },
            { sequence: 4, top: 23.96460843373494, left: 70.4417670682731 },
            { sequence: 5, top: 31.675451807228917, left: 83.45381526104417 },
            { sequence: 6, top: 23.000753012048193, left: 28.514056224899598 },
            { sequence: 7, top: 16.856174698795183, left: 12.369477911646587 },
            { sequence: 8, top: 39.1453313253012, left: 21.847389558232933 },
            { sequence: 9, top: 55.77183734939759, left: 16.947791164658636 },
            { sequence: 10, top: 61.67545180722892, left: 35.502008032128515 },
            { sequence: 11, top: 64, left: 71 },
            { sequence: 12, top: 89, left: 14 },
        ],
    },
    bank: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 13, left: 53 },
            { sequence: 1, top: 28, left: 54 },
            { sequence: 2, top: 34.8224038570191, left: 67.8714859437751 },
            { sequence: 3, top: 45.670270066669175, left: 81.6867469879518 },
            { sequence: 4, top: 59.77249613921428, left: 92.1285140562249 },
            { sequence: 5, top: 83.63780180044445, left: 52.93172690763053 },
            { sequence: 6, top: 58.68770951824928, left: 53.333333333333336 },
            { sequence: 7, top: 79.78078270367999, left: 29.076305220883537 },
            { sequence: 8, top: 64, left: 30 },
            { sequence: 9, top: 67.60706617951712, left: 9.477911646586346 },
            { sequence: 10, top: 48.08090700214697, left: 10.843373493975903 },
            { sequence: 11, top: 43.0185694376436, left: 26.506024096385545 },
            { sequence: 12, top: 34.701872010245204, left: 41.76706827309237 },
        ],
    },
    backalley: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 94, left: 22 },
            { sequence: 1, top: 80, left: 29 },
            { sequence: 2, top: 55, left: 21 },
            { sequence: 3, top: 17, left: 25 },
            { sequence: 4, top: 15, left: 44 },
            { sequence: 5, top: 58, left: 46 },
            { sequence: 6, top: 35, left: 62 },
            { sequence: 7, top: 49.165693623111984, left: 66.8273092369478 },
            { sequence: 8, top: 18, left: 82 },
            { sequence: 9, top: 57.48239105051037, left: 79.91967871485943 },
            { sequence: 10, top: 84.72258842140947, left: 76.3855421686747 },
            { sequence: 11, top: 93, left: 64 },
            { sequence: 12, top: 94, left: 44 },
        ],
    },
    azteckingdom: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 94, left: 15 },
            { sequence: 1, top: 81, left: 20 },
            { sequence: 2, top: 75.44163621981996, left: 41.285140562249 },
            { sequence: 3, top: 56.51813627631926, left: 24.819277108433734 },
            { sequence: 4, top: 53.14324456665035, left: 40.401606425702816 },
            { sequence: 5, top: 37.59463633281856, left: 36.46586345381526 },
            { sequence: 6, top: 23.974537647369015, left: 25.140562248995984 },
            { sequence: 7, top: 16, left: 49 },
            { sequence: 8, top: 29.03687521187239, left: 65.5421686746988 },
            { sequence: 9, top: 39, left: 86 },
            { sequence: 10, top: 57, left: 58 },
            { sequence: 11, top: 79, left: 66 },
            { sequence: 12, top: 87.49482089720894, left: 90.60240963855422 },
        ],
    },
    arena: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 81, left: 94 },
            { sequence: 1, top: 73.27206297788995, left: 87.55020080321285 },
            { sequence: 2, top: 57.48239105051037, left: 74.05622489959839 },
            { sequence: 3, top: 43.259633131191386, left: 79.67871485943775 },
            { sequence: 4, top: 18.671136389317866, left: 77.34939759036145 },
            { sequence: 5, top: 8.425929413537233, left: 59.11646586345382 },
            { sequence: 6, top: 25.05932426833402, left: 58.554216867469876 },
            { sequence: 7, top: 23.974537647369015, left: 40.963855421686745 },
            { sequence: 8, top: 21.925496252212888, left: 26.827309236947794 },
            { sequence: 9, top: 42.89803759086971, left: 18.55421686746988 },
            { sequence: 10, top: 42, left: 42 },
            { sequence: 11, top: 56, left: 54 },
            { sequence: 12, top: 84, left: 65 },
        ],
    },
    airport_standard: {
        wrapperType: "standard",
        zoomable: true,
        positions: [
            { sequence: 0, top: 20.10918674698795, left: 1.8473895582329318 },
            { sequence: 1, top: 16.976656626506024, left: 15.903614457831324 },
            { sequence: 2, top: 9.988704819277109, left: 43.45381526104418 },
            { sequence: 3, top: 7.097138554216867, left: 67.2289156626506 },
            { sequence: 4, top: 7.579066265060241, left: 93.65461847389558 },
            { sequence: 5, top: 15.169427710843372, left: 98.3132530120482 },
            { sequence: 6, top: 28.301957831325304, left: 95.90361445783132 },
            { sequence: 7, top: 44, left: 94 },
            { sequence: 8, top: 36, left: 80 },
            { sequence: 9, top: 32, left: 55 },
            { sequence: 10, top: 44, left: 36 },
            { sequence: 11, top: 53, left: 10 },
            { sequence: 12, top: 56, left: 53 },
        ],
    },

    airport: {
        wrapperType: "animated",
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [{
                name: "greenlight",
                top: "56",
                left: "57",
                width: "1",
                opacity: 95,
                rotate: 5,
            },
            {
                name: "light1",
                top: "8",
                left: "85",
                width: "6",
                rotate: 1,
            },
            {
                name: "carc",
                top: "85",
                left: "80",
                width: "18",
            },
            {
                name: "car1-c",
                top: "81",
                left: "5",
                width: "17",
                opacity: 2,
            },
            {
                name: "airportboard",
                top: "23",
                left: "67",
                width: "12",
                opacity: 10,
            },
            {
                name: "light2",
                top: "56",
                left: "57",
                width: "1",
                opacity: 95,
                rotate: 5,
            },
            {
                name: "men1",
                top: "76",
                left: "62",
                width: "7",
            },
            {
                name: "men2",
                top: "55",
                left: "79",
                width: "5",
                opacity: 8,
                rotate: "",
            },
            {
                name: "redlightleft",
                top: "59",
                left: "39",
                width: "3",
                opacity: 80,
                rotate: "-30",
            },
        ],
        positions: [
                {sequence:0,top:15,left:10},
                {sequence:1,top:10,left:30},
                {sequence:2,top:7,left:47},
                {sequence:3,top:6,left:78},
                {sequence:4,top:8,left:94},
                {sequence:5,top:28,left:59},
                {sequence:6,top:35,left:76},
                {sequence:7,top:52,left:91},
                {sequence:8,top:75,left:79},
                {sequence:9,top:67,left:12},
                {sequence:10,top:39,left:37},
                {sequence:11,top:49,left:9}
        ],
    },

    medieval: {
        wrapperType: "animated",
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [{
                name: "surf",
                top: "82",
                left: "79",
                width: "8",
                opacity: 0.5,
            },
            {
                name: "surf",
                top: "90",
                left: "27",
                width: "6",
                rotate: 45,
            },
            {
                name: "boat",
                top: "79",
                left: "0",
                width: "16",
            },
            {
                name: "smoke",
                top: "-18",
                left: "72.5",
                width: "17",
                opacity: 0.3,
            },
            {
                name: "smoke",
                top: "58",
                left: "55",
                width: "8",
                opacity: 0.5,
            },
            {
                name: "smoke",
                top: "-20",
                left: "11.5",
                width: "17",
                opacity: 0.5,
            },
            {
                name: "horse",
                top: "43",
                left: "82",
                width: "6",
            },
            {
                name: "ripples",
                top: "86",
                left: "83",
                width: "20",
                opacity: 0.3,
                rotate: "-60",
            },
        ],
        positions: [
            { sequence: 0, top: 86, left: 8 },
            { sequence: 1, top: 70, left: 33 },
            { sequence: 2, top: 83, left: 40 },
            { sequence: 3, top: 77, left: 69 },
            { sequence: 4, top: 51, left: 92 },
            { sequence: 5, top: 58, left: 62 },
            { sequence: 6, top: 31, left: 79 },
            { sequence: 7, top: 9, left: 52 },
            { sequence: 8, top: 30, left: 19 },
            { sequence: 9, top: 55, left: 44 },
            { sequence: 10, top: 48, left: 33 },
            { sequence: 11, top: 20, left: 43 },
        ],
    },
    cityscape: {
        wrapperType: "animated",
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [{
                sequence: 0,
                left: "85",
                top: "90",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 1,
                left: "58",
                top: "79",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 2,
                left: "7",
                top: "87",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 3,
                left: "16",
                top: "62",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 4,
                left: "45",
                top: "64",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 5,
                left: "70",
                top: "57",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 6,
                left: "36",
                top: "36",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 7,
                left: "88",
                top: "21",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 8,
                left: "4",
                top: "34",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 9,
                left: "60",
                top: "33",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 10,
                left: "60",
                top: "12",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 11,
                left: "34",
                top: "15",
                width: "9.6",
                height: "0",
            },
        ],
    },
    carnival: {
        wrapperType: "animated",
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            { sequence: 0, top: 60, left: 8 },
            { sequence: 1, top: 70, left: 25 },
            { sequence: 2, top: 68, left: 40 },
            { sequence: 3, top: 71, left: 70 },
            { sequence: 4, top: 56, left: 80 },
            { sequence: 5, top: 41, left: 81 },
            { sequence: 6, top: 18, left: 55 },
            { sequence: 7, top: 14, left: 44 },
            { sequence: 8, top: 36, left: 53 },
            { sequence: 9, top: 43, left: 33 },
            { sequence: 10, top: 41, left: 11 },
            { sequence: 11, top: 19, left: 15 },
        ],
    },
    supermarket: {
        wrapperType: "animated",
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            { sequence: 0, top: 41, left: 8 },
            { sequence: 1, top: 30, left: 22 },
            { sequence: 2, top: 37.94921875, left: 34.583333333333336 },
            { sequence: 3, top: 48.44921875, left: 48.66666666666667 },
            { sequence: 4, top: 54, left: 59 },
            { sequence: 5, top: 45, left: 65 },
            { sequence: 6, top: 33, left: 67 },
            { sequence: 7, top: 32, left: 49 },
            { sequence: 8, top: 17, left: 46 },
            { sequence: 9, top: 17, left: 62 },
            { sequence: 10, top: 21, left: 74 },
            { sequence: 11, top: 26, left: 86 },
        ],
    },
    atlantis: {
        wrapperType: "animated",
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [{
                name: "A",
                top: "49",
                left: "30",
                width: "23",
            },
            {
                name: "B",
                top: "76",
                left: "52",
                width: "13",
            },
            {
                name: "C",
                top: "40",
                left: "73",
                width: "16",
            },
            {
                name: "D",
                top: "22",
                left: "86",
                width: "14",
            },
            {
                name: "E",
                top: "76",
                left: "18",
                width: "9",
            },
            {
                name: "F",
                top: "30",
                left: "61",
                width: "9",
            },
            {
                name: "Water",
                top: "0",
                left: "0",
                width: "100",
            },
        ],
        positions: [
            { sequence: 0, top: 73.87472221175939, left: 44.57831325301205 },
            { sequence: 1, top: 67.96866171983879, left: 73.4136546184739 },
            { sequence: 2, top: 40, left: 89 },
            { sequence: 3, top: 52, left: 63 },
            { sequence: 4, top: 52, left: 49 },
            { sequence: 5, top: 38.3178274134619, left: 51.80722891566265 },
            { sequence: 6, top: 42, left: 22 },
            { sequence: 7, top: 27.83155674413349, left: 81.20481927710843 },
            { sequence: 8, top: 17.224754228031188, left: 71.96787148594377 },
            { sequence: 9, top: 18.912200082865642, left: 55.983935742971894 },
            { sequence: 10, top: 14.452521752231723, left: 35.18072289156626 },
            { sequence: 11, top: 28.19315228445516, left: 24.819277108433734 },
        ],
    },
    mountain_adventure: {
        wrapperType: "animated",
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        mover: true,
        directionalMover: false,
        gifMap: [{
                name: "Boat_1",
                top: "82",
                left: "9",
                width: "5",
                height: "5",
            },
            {
                name: "Boat_1",
                top: "82",
                left: "89",
                width: "5",
                height: "5",
            },
            {
                name: "Flag",
                top: "77",
                left: "53",
                width: "2",
                height: "2",
            },
            {
                name: "Flag",
                top: "41",
                left: "59",
                width: "2",
                height: "2",
            },
            {
                name: "Flag",
                top: "11",
                left: "58",
                width: "2",
                height: "2",
            },
            {
                name: "Flag",
                top: "30",
                left: "33",
                width: "2",
                height: "2",
            },
            {
                name: "Flag",
                top: "31",
                left: "72",
                width: "2",
                height: "2",
            },
            {
                name: "Flag",
                top: "25",
                left: "21",
                width: "2",
                height: "2",
            },
            {
                name: "Fog",
                top: "0",
                left: "0",
                width: "100",
                height: "100",
            },
            {
                name: "Sea",
                top: "0",
                left: "0",
                width: "100",
                height: "100",
            },
            {
                name: "gondala set 2",
                top: "59",
                left: "15",
                width: "3",
                height: "3",
            },
            {
                name: "gondala set 1",
                top: "51",
                left: "21",
                width: "3",
                height: "3",
            },
        ],
        positions: [
            { sequence: 0, top: 77.21762048192771, left: 53.73493975903615 },
            { sequence: 1, top: 60, left: 13 },
            { sequence: 2, top: 31, left: 33 },
            { sequence: 3, top: 59.50677710843374, left: 79.03614457831326 },
            { sequence: 4, top: 53, left: 67 },
            { sequence: 5, top: 50, left: 84 },
            { sequence: 6, top: 47, left: 79 },
            { sequence: 7, top: 42, left: 60 },
            { sequence: 8, top: 32, left: 73 },
            { sequence: 9, top: 27.699548192771083, left: 21.044176706827308 },
            { sequence: 10, top: 15.289909638554217, left: 57.188755020080315 },
            { sequence: 11, top: 9.494728915662651, left: 49.47791164658635 },
        ],
    },
    apartments: {
        wrapperType: "animated",
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [{
                name: "Car 1",
                top: "60",
                left: "80",
                width: "25",
            },
            {
                name: "Car 4",
                top: "63",
                left: "87",
                width: "25",
            },
            {
                name: "Car 3",
                top: "72",
                left: "-7",
                width: "20",
            },
            {
                name: "Car 2",
                top: "72",
                left: "-3",
                width: "20",
            },
            {
                name: "Water lake",
                top: "35",
                left: "43",
                width: "15",
            },
            {
                name: "Pool party",
                top: "14",
                left: "48",
                width: "16",
            },
            {
                name: "Terrace party",
                top: "2",
                left: "18",
                width: "19",
            },
            {
                name: "Terrace party",
                top: "2",
                left: "18",
                width: "19",
            },
            {
                name: "swing",
                top: "29",
                left: "39",
                width: "6",
            },
            {
                name: "Ringlight",
                top: "29",
                left: "26",
                width: "7",
            },
            {
                name: "Ringlight",
                top: "50",
                left: "45",
                width: "10",
            },
            {
                name: "Ringlight",
                top: "26",
                left: "67",
                width: "6",
            },
            {
                name: "restaurant",
                top: "6",
                left: "85",
                width: "10",
            },
        ],
        positions: [
            { sequence: 0, top: 59, left: 77 },
            { sequence: 1, top: 67, left: 30 },
            { sequence: 2, top: 46, left: 45 },
            { sequence: 3, top: 37.35357263927078, left: 49.558232931726906 },
            { sequence: 4, top: 22, left: 42 },
            { sequence: 5, top: 32, left: 64 },
            { sequence: 6, top: 11, left: 70 },
            { sequence: 7, top: 14, left: 86 },
            { sequence: 8, top: 13, left: 49 },
            { sequence: 9, top: 6, left: 36 },
            { sequence: 10, top: 12, left: 21 },
            { sequence: 11, top: 56, left: 13 },
        ],
    },
    aquarium: {
        wrapperType: "animated",
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [{
                name: "rightbottom",
                top: "68",
                left: "71",
                width: "17",
            },
            {
                name: "flask",
                top: "71",
                left: "10",
                width: "6",
            },
            {
                name: "red fish",
                top: "10",
                left: "64",
                width: "11",
                opacity: 0.8,
            },
            {
                name: "single fish",
                top: "14",
                left: "68",
                width: "10",
                opacity: 0.5,
            },
            {
                name: "yellow fish",
                top: "10",
                left: "75",
                width: "6",
                opacity: 0.7,
            },
            {
                name: "tank",
                top: "9",
                left: "42",
                width: "16",
            },
            {
                name: "bubble",
                top: "5",
                left: "38",
                width: "19",
            },
            {
                name: "tank2",
                top: "34",
                left: "42",
                width: "16",
            },
            {
                name: "bubble",
                top: "34",
                left: "42",
                width: "15",
            },
            {
                name: "single fish 2",
                top: "39",
                left: "45",
                width: "10",
            },
            {
                name: "single fish 3.1",
                top: "5",
                left: "44",
                width: "10",
            },
            {
                name: "left",
                top: "8",
                left: "24",
                width: "9",
            },
            {
                name: "ripples",
                top: "86",
                left: "83",
                width: "20",
                opacity: 0.3,
                rotate: "-60",
            },
        ],
        positions: [
            { sequence: 0, top: 84, left: 75 },
            { sequence: 1, top: 60, left: 82 },
            { sequence: 2, top: 64, left: 53 },
            { sequence: 3, top: 66, left: 13 },
            { sequence: 4, top: 56, left: 36 },
            { sequence: 5, top: 32, left: 77 },
            { sequence: 6, top: 37, left: 50 },
            { sequence: 7, top: 27, left: 61 },
            { sequence: 8, top: 15, left: 78 },
            { sequence: 9, top: 13, left: 40 },
            { sequence: 10, top: 15, left: 17 },
            { sequence: 11, top: 41, left: 10 },
        ],
    },
    asylum: {
        wrapperType: "animated",
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [{
                name: "book reading",
                top: "49",
                left: "84",
                width: "7",
            },
            {
                name: "chandelier1",
                top: "40",
                left: "46",
                width: "9",
            },
            {
                name: "hospital charac",
                top: "60",
                left: "14",
                width: "3",
            },
            {
                name: "hotel left",
                top: "22",
                left: "36",
                width: "9",
                opacity: 0.8,
            },
            {
                name: "right2",
                top: "22",
                left: "56",
                width: "8",
                opacity: 0.8,
            },
            {
                name: "layer 3",
                top: "41",
                left: "62",
                width: "18",
                opacity: 0.8,
            },
            {
                name: "Men On Call",
                top: "51",
                left: "54",
                width: "2",
            },
            {
                name: "Men On sofa",
                top: "80",
                left: "22",
                width: "5",
            },
            {
                name: "old man",
                top: "63",
                left: "13",
                width: "28",
            },
            {
                name: "reception",
                top: "76",
                left: "69",
                width: "9",
            },
            {
                name: "waiter 2",
                top: "24",
                left: "64",
                width: "12",
            },
        ],
        positions: [
            { sequence: 0, top: 74, left: 50 },
            { sequence: 1, top: 71, left: 88 },
            { sequence: 2, top: 57, left: 38 },
            { sequence: 3, top: 46.51399299408641, left: 81.44578313253012 },
            { sequence: 4, top: 44, left: 54 },
            { sequence: 5, top: 42.05431466345249, left: 32.53012048192771 },
            { sequence: 6, top: 22.64868733285623, left: 80.64257028112449 },
            { sequence: 7, top: 22.889751026404006, left: 58.152610441767074 },
            { sequence: 8, top: 19.635391163508984, left: 22.971887550200805 },
            { sequence: 9, top: 11, left: 69.87951807228916 },
            { sequence: 10, top: 10.461674639346115, left: 45.46184738955824 },
            { sequence: 11, top: 11, left: 17 },
        ],
    },
    bus_station: {
        wrapperType: "animated",
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [{
                name: "board",
                top: "33",
                left: "37",
                width: "13",
            },
            {
                name: "box2",
                top: "7",
                left: "51",
                width: "7",
            },
            {
                name: "bus left",
                top: "75",
                left: "-1",
                width: "40",
            },
            {
                name: "bus right",
                top: "68",
                left: "53",
                width: "46",
            },
            {
                name: "car",
                top: "67",
                left: "25",
                width: "30",
            },
            {
                name: "talking",
                top: "47",
                left: "55",
                width: "10",
            },
            {
                name: "women walkin",
                top: "42",
                left: "6",
                width: "9",
            },
            {
                name: "women walkin",
                top: "57",
                left: "65",
                width: "10",
            },
        ],
        positions: [
            { sequence: 0, top: 33.96460843373494, left: 22.48995983935743 },
            { sequence: 1, top: 38, left: 39 },
            { sequence: 2, top: 36, left: 48 },
            { sequence: 3, top: 11.675451807228916, left: 30.522088353413658 },
            { sequence: 4, top: 25, left: 58 },
            { sequence: 5, top: 6, left: 51 },
            { sequence: 6, top: 48, left: 48 },
            { sequence: 7, top: 40, left: 68 },
            { sequence: 8, top: 36, left: 93 },
            { sequence: 9, top: 49, left: 74 },
            { sequence: 10, top: 56, left: 65 },
            { sequence: 11, top: 55, left: 88 },
        ],
    },
    club: {
        wrapperType: "animated",
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [{
                top: "84",
                left: "60",
                width: "40",
                sequence: 0,
            },
            {
                top: "83",
                left: "30",
                width: "45",
                sequence: 1,
            },
            {
                top: "69",
                left: "68",
                width: "40",
                sequence: 2,
            },
            {
                top: "69",
                left: "14",
                width: "48",
                sequence: 3,
            },
            {
                top: "58",
                left: "18",
                width: "40",
                sequence: 4,
            },
            {
                top: "37",
                left: "74",
                width: "35",
                sequence: 5,
            },
            {
                top: "21",
                left: "33",
                width: "37",
                sequence: 6,
            },
            {
                top: "31",
                right: "63",
                width: "36",
                sequence: 7,
            },
            {
                top: "14",
                left: "63",
                width: "35",
                sequence: 8,
            },
            {
                top: "45",
                left: "1",
                width: "40",
                sequence: 9,
            },
            {
                top: "86",
                right: "64",
                width: "50",
                sequence: 10,
            },
            {
                top: "15",
                left: "15",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
};

export const PickedLocations = {
    1: [0],
    2: [0, 11],
    3: [0, 5, 11],
    4: [0, 4, 8, 11],
    5: [0, 3, 6, 9, 11],
    6: [0, 2, 4, 6, 9, 11],
    7: [0, 2, 4, 6, 8, 10, 11],
    8: [0, 1, 2, 4, 6, 8, 10, 11],
    9: [0, 1, 2, 5, 6, 7, 9, 10, 11],
    10: [0, 1, 2, 4, 5, 6, 7, 9, 10, 11],
    11: [0, 1, 2, 3, 4, 6, 7, 8, 9, 10, 11],
    12: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
};