// Import from NPM
// -------------------------------------
import React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Div } from "@components/Generics.react";
import { deckFormats } from "@schemas/deckFormats";
import QdfSettings from "../settingsPanels/QdfSettings.react";
import AssessmentSettings from "../settingsPanels/AssessmentsSettings.react";
import SurveySettings from "../settingsPanels/SurveySettings.react";
import GameSettings from "../settingsPanels/GameSettings.react";

export default function DeckSettings(props) {
    const { deck } = props;
    const deckTemplate = _.find(deckFormats, { readerType: deck.readerType });
    const settingsPanels = {
        qdf: QdfSettings,
        assessment: AssessmentSettings,
        survey: SurveySettings,
        game: GameSettings,
    };
    const SettingsPanel = settingsPanels[deck.readerType];
    const { t } = useTranslation("common");
    // ========================= Render Function =================================
    return (
        <Div
            minWd="400px"
            wd="400px"
            fullht
            relative
            peat
            basepad
            ht={`calc(100vh - 225px)`}
            darkInset
        >
            <Div white fullht rounded basepad slightShadow>
                <Div bold large compact uppercase>
                    {deckTemplate.name} {t("builder.deck.settings")}:
                </Div>
                <Div italics gutter compact>
                    {t("builder.deck.settingsHelp").replace(
                        "$template",
                        deckTemplate.name.toLowerCase()
                    )}
                </Div>
                <Div ht="calc(100% - 100px)" autoOverflowY>
                    <SettingsPanel {...props} />
                </Div>
            </Div>
        </Div>
    );
}
