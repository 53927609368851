import React, { useState } from "react";
import _ from "lodash";
import {
  useSendUserSearchMutation,
  useGetCourseLearnersQuery,
  useSendRemoveLearnerMutation,
} from "@api/apiV6";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFeatures } from "flagged";

import { Div, Button } from "@components/Generics.react";

import { Modal, Input, Icon } from "semantic-ui-react";

export default function EnrollLearnerOperations(props) {
  const { getUser, setGetUser, removeUser, setRemoveUser, course } = props;
  const ownerUser = useSelector((state) => state.auth.user);
  const features = useFeatures();
  const { t } = useTranslation("common");

  const [searchData, { isLoading }] = useSendUserSearchMutation();
  const { data: courseLearners } = useGetCourseLearnersQuery(course._id);
  const [removeLearners] = useSendRemoveLearnerMutation();

  const [loading, setLoading] = useState(false);
  const [searchFor, setSearchFor] = useState("");
  const [results, setResults] = useState([]);
  const [showingResults, setShowingResults] = useState(false);
  const [unEnrollModalOpen, setUnEnrollModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  function handleSearchChange() {
    if (searchFor) {
      let users = searchData(searchFor);
      setLoading(isLoading);
      users.then((res) => {
        let filteredResults;
        if (features.ability.builder.crossAccess) {
          filteredResults = _.filter(res.data, (dataItem) =>
            courseLearners.some((learner) => learner._id === dataItem._id)
          );
        } else {
          filteredResults = _.filter(res.data, { team: ownerUser.team });
        }
        setResults(filteredResults);
        setShowingResults(true);
        setLoading(false);
      });
    }
  }

  function handleEnterKeyPress(e) {
    if (e.key === "Enter") {
      handleSearchChange();
    }
  }

  function initializeStateAndClose() {
    setGetUser(false);
    setRemoveUser(false);
    setSearchFor("");
    setResults([]);
  }

  function unEnrollUser() {
    removeLearners({ id: course?._id, user_id: selectedUserId });
    setUnEnrollModalOpen(false);
    initializeStateAndClose();
  }

  // Function to render search results
  const renderSearchResults = () => {
    return (
      <Div fluid ht="200px" autoOverflow>
        {results.length > 0 ? (
          <Div>
            {results.map((result, idx) => (
              <Div spaceBottom key={idx} ivory medpad flex spaceBetween>
                <Div>
                  {idx + 1} : USERNAME : {result.username}{" "}
                  {getUser && "is present in the course"}
                </Div>
                {removeUser && (
                  <Div txtDanger inline clickable>
                    <Icon
                      name="trash"
                      onClick={() => {
                        setUnEnrollModalOpen(true);
                        setSelectedUserId(result._id);
                      }}
                      title={t("unenroll")}
                      disabled={result._id === course.owner._id}
                    />
                  </Div>
                )}
              </Div>
            ))}
          </Div>
        ) : (
          <Div>No results found...</Div>
        )}
      </Div>
    );
  };

  const ConfirmationModal = ({ open, onClose, onConfirm }) => (
    <Modal size="small" open={open} onClose={onClose}>
      <Modal.Header>Last chance to reconsider...</Modal.Header>
      <Modal.Content>
        <p>Are you sure you want to unenroll?</p>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onClose}>
          No
        </Button>
        <Button positive onClick={onConfirm}>
          Yes
        </Button>
      </Modal.Actions>
    </Modal>
  );

  // ========================= Render Function =================================
  return (
    <Modal
      size="small"
      open={getUser || removeUser}
      onClose={initializeStateAndClose}
    >
      <Modal.Header>
        {getUser
          ? "Check if Enrolled"
          : removeUser
          ? "Unenroll User"
          : "Message User"}
        <Div float-right clickable>
          <Icon name="times" onClick={initializeStateAndClose} />
        </Div>
      </Modal.Header>
      <Modal.Content>
        <Div>
          <Div fluid gutter>
            <Input
              fluid
              action={{
                color: "teal",
                labelPosition: "left",
                icon: "search",
                content: "Search",
                onClick: handleSearchChange,
                loading: loading,
              }}
              placeholder="Search by username..."
              value={searchFor}
              onChange={(e) => setSearchFor(e.target.value)}
              onKeyPress={handleEnterKeyPress}
            />
          </Div>
          {showingResults && renderSearchResults()}
        </Div>
      </Modal.Content>
      <ConfirmationModal
        open={unEnrollModalOpen}
        onClose={() => setUnEnrollModalOpen(false)}
        onConfirm={unEnrollUser}
      />
    </Modal>
  );
}
