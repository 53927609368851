import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import { Loader, Input } from "semantic-ui-react";
import { Div, Button } from "@components/Generics.react";

const PaginatedTable = (props) => {
    const { queryFn, queryArgs, columns, setTableRef } = props;
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [searchText, setSearchText] = useState("");
    const [searchParam, setSearchParam] = useState("");

    const { data, error, isLoading, refetch } = queryFn({
        ...queryArgs,
        page,
        pageSize,
        search: searchParam.length > 3 ? searchParam : undefined,
    });
    const reactTableRef = useRef(null);
    const { t } = useTranslation("common");

    const reactTableData = data?.items || [];
    const filteredReactTableData = reactTableData?.filter((user) => user.username !== "sysadmin");
    const pages = Math.ceil(data?.total / pageSize) || 1;

    useEffect(() => {
        // This effect ensures the table refreshes when pagination controls are used
    }, [page, pageSize, queryFn, queryArgs, searchParam]);

    useEffect(() => {
        setTableRef(reactTableRef);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reactTableRef]);

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            setSearchParam(searchText);
        }
    };

    if (error) return <p>Error fetching data!</p>;
    else
        return (
            <>
                <Div fluid ash rimmed flex spaceBetween>
                    <Input
                        placeholder={t("builder.dic.searchWithin")}
                        icon={"search"}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyPress={handleKeyPress}
                    />
                    <Button
                        size="small"
                        secondary
                        icon="refresh"
                        labelPosition="left"
                        content={t("admin.userMgmt.refresh")}
                        onClick={refetch}
                    />
                </Div>
                <ReactTable
                    ref={reactTableRef}
                    columns={columns}
                    data={filteredReactTableData}
                    pages={pages}
                    loading={isLoading}
                    manual // Inform React Table that you'll be handling pagination server-side
                    onFetchData={(state) => {
                        setPage(state.page);
                        setPageSize(state.pageSize);
                    }}
                    defaultPageSize={10}
                    className="-striped -highlight"
                />
                {isLoading && <Loader />}
            </>
        );
};

export default PaginatedTable;
