import React, { useState, useEffect, useMemo, useCallback } from "react";
import _ from "lodash";
import {
    useSendUserSearchMutation,
    useGetCourseLearnersQuery,
    useCreateInNotificationMutation,
    useCreateEmailNotificationMutation
} from "@api/apiV6";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFeatures } from "flagged";

import { Div, Button } from "@components/Generics.react";
import { notificationConfig } from "@config/notifications/config.notification";

import { Modal, Input, Icon, TextArea, Checkbox } from "semantic-ui-react";

export default function NotifyWrapper(props) {
    const { notifyUser, setNotifyUser, course } = props;
    const ownerUser = useSelector((state) => state.auth.user);
    const features = useFeatures();
    const { t } = useTranslation("common");
    const initialSelectAll = false;
    const initialSelectedUserIds = useMemo(() => [], []);

    const [searchData] = useSendUserSearchMutation();
    const { data: courseLearners } = useGetCourseLearnersQuery(course._id);
    const [triggerInNotification] = useCreateInNotificationMutation();
    const [triggerEmailNotification] = useCreateEmailNotificationMutation();

    const [loading, setLoading] = useState(false);
    const [searchFor, setSearchFor] = useState("");
    const [results, setResults] = useState([]);
    const [messageTitle, setMessageTitle] = useState("Welcome to my page");
    const [messageContent, setMessageContent] = useState("I would love to invite you on an interactive journey with us...");
    const [selectAll, setSelectAll] = useState(initialSelectAll);
    const [selectedUserIds, setSelectedUserIds] = useState(initialSelectedUserIds);

    const handleSearchChange = useCallback(() => {
        setLoading(true);
        searchData(searchFor).then((res) => {
            let filteredResults;
            if (features.ability.builder.crossAccess) {
                filteredResults = _.filter(res.data, (dataItem) =>
                    courseLearners.some((learner) => learner._id === dataItem._id)
                );
            } else {
                filteredResults = _.filter(res.data, { team: ownerUser.team });
            }
            setResults(filteredResults);
            setLoading(false);
        });
    }, [searchFor, searchData, features.ability.builder.crossAccess, courseLearners, ownerUser.team]);

    useEffect(() => {
        if (searchFor.trim() === "") {
            setResults([]);
            setSelectedUserIds(initialSelectedUserIds);
            setSelectAll(initialSelectAll);
            return;
        }
        const search = _.debounce(handleSearchChange, 300);
        search();
        return () => {
            search.cancel(); // Cancel debounce on unmount or re-trigger
        };
    }, [searchFor, handleSearchChange, initialSelectAll, initialSelectedUserIds]);

    function handleEnterKeyPress(e) {
        if (e.key === "Enter") {
            handleSearchChange();
        }
    }

    function handleUserNoti(checked, userId) {
        const updatedUserIds = checked
            ? [...selectedUserIds, userId]
            : selectedUserIds.filter((id) => id !== userId);

        setSelectedUserIds(updatedUserIds);
        setSelectAll(updatedUserIds.length === results.length);
    }

    function handleAllUserNoti() {
        setSelectAll(!selectAll);
        if (!selectAll) {
            const allUserIds = results.map((result) => result._id);
            setSelectedUserIds(allUserIds);
        } else {
            setSelectedUserIds([]);
        }
    }

    function initializeStateAndClose() {
        setNotifyUser(false);
        setSearchFor("");
        setResults([]);
        setSelectedUserIds(initialSelectedUserIds);
        setSelectAll(initialSelectAll);
    }

    const updateNotifications = () => {
        // Handle update notifications logic here
        if (notificationConfig.broadcastMessage.inAppNotification || notificationConfig.broadcastMessage.emailNotification) {
            if (notificationConfig.broadcastMessage.inAppNotification) {
                selectedUserIds.forEach(userId => {
                    let message = _.pick(
                        notificationConfig.broadcastMessage,
                        "type",
                        "title",
                        "description",
                        "link"
                    );
                    message.title = messageTitle;
                    message.description = messageContent;
                    triggerInNotification({
                        _id: userId,
                        message: message,
                    });
                });
            }
            if (notificationConfig.broadcastMessage.emailNotification) {
                selectedUserIds.forEach(userId => {
                    triggerEmailNotification({
                        _id: userId,
                        route: notificationConfig.broadcastMessage.route,
                        message: {
                            subject: messageTitle,
                            body: messageContent,
                        },
                        emailType: "broadcast_message",
                    });
                });
            }
        }
        initializeStateAndClose();
    };

    return (
        <Modal
            open={notifyUser}
            onClose={initializeStateAndClose}
        >
            <Modal.Header>
                {t(`builder.course.broadcastMsg.header`)}
                <Div float-right clickable>
                    <Icon
                        name="times"
                        onClick={initializeStateAndClose}
                    />
                </Div>
            </Modal.Header>
            <Modal.Content>
                <Div>
                    <Div fluid gutter>
                        <Input
                            fluid
                            action={{
                                color: "teal",
                                labelPosition: "left",
                                icon: "search",
                                content: "Search",
                                onClick: handleSearchChange,
                                loading: loading,
                            }}
                            placeholder={t(`builder.course.broadcastMsg.search`)}
                            value={searchFor}
                            onChange={(e) => setSearchFor(e.target.value)}
                            onKeyPress={handleEnterKeyPress}
                        />
                    </Div>
                    {results.length > 0 &&
                        <Div flex fluid ht="500px">
                            <Div bordered wd="50%" fullht rimmed>
                                <Checkbox
                                    checked={selectAll}
                                    label={t(`builder.course.broadcastMsg.selectAll`)}
                                    onChange={handleAllUserNoti}
                                />
                                {results.map((result, idx) => (
                                    <Div
                                        spaceBottom
                                        key={idx}
                                        ivory
                                        medpad
                                    >
                                        <Checkbox
                                            id={result._id}
                                            label={result.username}
                                            checked={selectedUserIds.includes(result._id)}
                                            onChange={(e, data) => handleUserNoti(data.checked, result._id)}
                                        />
                                    </Div>
                                ))}
                            </Div>
                            <Div bordered wd="50%" fullht rimmed>
                                <Div fluid basepad ivory>
                                    <Div huge bold>
                                        {t(`builder.course.broadcastMsg.header`)}
                                    </Div>
                                </Div>
                                <Div fluid basepad>
                                    <Div big bold gutter>
                                        {t(`builder.course.broadcastMsg.title1`)}
                                    </Div>
                                    <Div gutter>
                                        {t(`builder.course.broadcastMsg.title2`)}
                                    </Div>
                                    <Div bold big gutter>
                                        {t(`builder.course.broadcastMsg.subLine`)}
                                    </Div>
                                    <Input
                                        fluid
                                        value={messageTitle}
                                        placeholder={t(`builder.course.broadcastMsg.msgTitle`)}
                                        onChange={(e) => setMessageTitle(e.target.value)}
                                    />
                                    <br />
                                    <Div bold big gutter>
                                        {t(`builder.course.broadcastMsg.customMsg`)}
                                    </Div>
                                    <TextArea
                                        value={messageContent}
                                        rows={5}
                                        placeholder={t(`builder.course.broadcastMsg.msgContent`)}
                                        onChange={(e) => setMessageContent(e.target.value)}
                                        style={{
                                            width: "100%",
                                            padding: "10px",
                                            fontSize: "13px",
                                            color: "rgba(0,0,0,0.87)",
                                            border: "1px solid #ccc",
                                        }}
                                    />
                                    <br />
                                    <br />
                                    <Button
                                        content={t(`components.save`)}
                                        labelPosition="left"
                                        primary
                                        icon="save"
                                        onClick={updateNotifications}
                                    />
                                </Div>
                            </Div>
                        </Div>}
                </Div>
            </Modal.Content>
        </Modal>
    );
}
