// Import from NPM
// -------------------------------------
import React from "react";
import _ from "lodash";
import { Div } from "@components/Generics.react";
import SubformBlock from "./formComponents/SubformBlock.react";

export default function SlideBuilder(props) {
    const { readerType, content, setContent, fields, slideNum } = props;

    function transpileContent(data) {
        const transpiled =
            typeof data === "string" ||
            data instanceof String ||
            _.isArray(data)
                ? data
                : { ...content, ...data };
        setContent(transpiled);
    }

    let typedFields = fields;

    switch (readerType) {
        case "survey":
            typedFields = _.filter(
                fields,
                (cmp) => !["feedback", "answer"].includes(cmp.target)
            );
            typedFields = _.map(typedFields, (optField) => {
                if (
                    ["options", "imageOpts", "choice", "multiselect"].includes(
                        optField.target
                    )
                )
                    return { ...optField, ...{ noRadio: true } };
                else return optField;
            });
            break;
        default:
    }

    // ========================= Render Function =================================
    return (
        <Div clearfix>
            {_.map(typedFields, (field, idx) => {
                if (_.isArray(field.formField)) {
                    return (
                        <Div
                            key={`form-field-${slideNum}-${idx}`}
                            wd={field.width}
                            float-left={field.width != null}
                            clear={field.width == null}
                            spaced
                        >
                            <SubformBlock
                                field={field}
                                hasStructure={
                                    typeof field.formField[0] === "object"
                                }
                                value={
                                    field.target && content
                                        ? content[field.target]
                                        : content
                                }
                                setValue={transpileContent}
                            />
                        </Div>
                    );
                } else {
                    const FieldComponent = field.formField;
                    return (
                        <Div
                            key={`form-field-${slideNum}-${idx}`}
                            wd={field.width}
                            float-left={field.width != null}
                            clear={field.width == null}
                            spaced
                        >
                            <FieldComponent
                                {...field}
                                value={content}
                                setValue={transpileContent}
                            />
                        </Div>
                    );
                }
            })}
        </Div>
    );
}
