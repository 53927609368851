export const accountBanner = {
    image: "/assets/images/account/appsumo.jpg",
    header: "Don't miss out on our exclusive AppSumo offer!",
    description:
        "To upgrade, you'll need to acquire more AppSumo codes, which can be done by clicking on the 'Go to AppSumo' button.",
    buttons: [
        {
            text: "Go to AppSumo",
            link: "https://appsumo.com/products/pitchiquo?query=pitchiquo",
            target: "_blank",
        },
        {
            text: "Redeem Codes",
            link: "/builder/account?target=redeem",
        },
    ],
};
export const comparePlansLink = "";
export const showLicenses = ["Essentials", "Pro", "Enterprise"];
export const showKeys = ["maxUsers", "maxSpace", "maxCourses"];
export const licenseSchema = {
    Free: {
        name: "Free",
        level: 0,
        price: { monthly: "Free", annual: "Free" },
        maxUsers: 5,
        maxDecks: 1,
        maxCourses: 1,
        maxArticles: 1,
        maxSpace: 10000,
        common: [
            "<b>Basic Content Formats</b>: <br/> Powerpoints, Videos, Quizzes, Certificates",
            "Integrated Helpdesk Support",
        ],
    },
    Essentials: {
        name: "Essentials",
        level: 1,
        price: { monthly: "$49/Month", annual: "$39/Month, paid annually" },
        planId: {
            monthly: "plan_MZnt6tia24nG92",
            annual: "plan_MZnt6tia24nG92",
        },
        maxUsers: 100,
        maxDecks: 3,
        maxCourses: 1,
        maxArticles: 1,
        maxSpace: 30000,
        common: [
            "<b>Essential Content Formats</b>: <br/> Powerpoints, Videos, Quizzes, Certificates, <b>Games, Flash Cards, Badges, Rewards</b> <br/><br/>",
            "<b>Design Customization</b>",
            "<b>Standard Gamified Lesson Themes</b>",
            "Integrated Helpdesk Support",
            "<b>Priority Email Support</b>",
        ],
    },
    Pro: {
        name: "Pro",
        level: 2,
        maxUsers: 1000,
        maxDecks: 5,
        maxCourses: 1,
        maxArticles: 1,
        maxSpace: 50000,
        price: { monthly: "$129/Month", annual: "$99/Month, paid annually" },
        planId: {
            monthly: "plan_MZnt6tia24nG92",
            annual: "plan_MZnt6tia24nG92",
        },
        common: [
            "<b>All Content Formats</b>: <br/> Powerpoints, Videos, Quizzes, Certificates, Games, Flash Cards, Badges, Rewards, <b>Web Stories, Assigments</b>",
            "Design Customization",
            "<b>Animated Gamified Lesson Themes</b>",
            "Integrated Helpdesk Support",
            "Priority Email Support",
            "<b>Ask Your Teacher Section</b>",
            "<b>Timed Lesson Release</b>",
            "<b>Enrollment Automation</b>",
            "<b>Reminders System</b>",
        ],
    },
    Enterprise: {
        name: "Enterprise",
        level: 3,
        price: { monthly: "Contact Us", annual: "Contact Us" },
        maxUsers: "As required",
        maxDecks: 10,
        maxCourses: "Unlimited",
        maxArticles: "Unlimited",
        maxSpace: "As required",
        common: [
            "Want more users, customized features, complete white labelling or anything else?",
        ],
    },
};
