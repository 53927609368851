import React, { useState, useEffect } from "react";
import _ from "lodash";

import { TemplateList } from "@schemas/templateSchema";
import { SlideLayout } from "@layouts/SlideLayout.react";
import "@styles/readers.scss";

import { Div, Button } from "@components/Generics.react";
import DeckToolbar from "@components/DeckToolbar.react";

export default function AssessmentReader(props) {
    const { deck: rawDeck, recordAttempt, isPreview, slideNum } = props;
    const isPortrait = window.innerHeight > window.innerWidth;

    //---------------------------------------------------------------------------
    // Assessment Setup
    //---------------------------------------------------------------------------
    const [deck, setDeck] = useState(rawDeck);
    useEffect(() => {
        if (rawDeck)
            if (
                rawDeck.qcount &&
                parseInt(rawDeck.qcount, 0) !== 0 &&
                parseInt(rawDeck.qcount, 0) !== rawDeck.content?.length
            ) {
                let newDeck = _.cloneDeep(rawDeck);
                const fullCount =
                    isPreview ||
                    parseInt(newDeck.qcount, 0) == null ||
                    parseInt(newDeck.qcount, 0) === 0;
                const content = fullCount
                    ? newDeck.content
                    : _.sampleSize(newDeck.content, newDeck.qcount);
                _.map(content, (slide, index) => {
                    slide["masterSeq"] = slide["slideSeq"];
                    slide["slideSeq"] = index;
                });
                newDeck.content = content;
                if (fullCount) newDeck.qcount = content.length;
                setDeck(newDeck);
            } else {
                let newDeck = _.cloneDeep(rawDeck);
                const content = !deck.enableShuffle
                    ? newDeck.content
                    : _.shuffle(newDeck.content);
                _.map(content, (slide, index) => {
                    slide["masterSeq"] = slide["slideSeq"];
                    slide["slideSeq"] = index;
                });
                newDeck.content = content;
                newDeck.qcount = content.length;
                setDeck(newDeck);
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rawDeck, isPreview]);

    //---------------------------------------------------------------------------
    // Slide Navigation
    //---------------------------------------------------------------------------
    const [startScreen, setStartScreen] = useState(!isPreview);
    const [pageNumber, setPageNumber] = useState(0);
    const [slideData, setSlideData] = useState(null);
    const [timerOver, setTimerOver] = useState(false);

    useEffect(() => {
        const currentSlide = _.find(deck.content, {
            slideSeq: pageNumber,
        });
        setSlideData(currentSlide);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, deck]);

    //---------------------------------------------------------------------------
    // Creator Slide Navigation
    //---------------------------------------------------------------------------
    useEffect(() => {
        if (slideNum != null && pageNumber !== slideNum)
            setPageNumber(slideNum);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slideNum, pageNumber]);

    //---------------------------------------------------------------------------
    // Slide Content
    //---------------------------------------------------------------------------
    const templateSchema = TemplateList[slideData?.template];
    const SlideComponent = templateSchema?.template;

    const title = templateSchema?.showHeader ? slideData.data.title : "";
    const subtitle = templateSchema?.showHeader ? slideData.data.subtitle : "";

    //---------------------------------------------------------------------------
    // Question Data Tracking
    //---------------------------------------------------------------------------
    const [questionsAttempted, setQuestionsAttempted] = useState(0);
    const [questionsCorrect, setQuestionsCorrect] = useState(0);
    function trackData(response) {
        setQuestionsAttempted(questionsAttempted + 1);
        if (response.correct) setQuestionsCorrect(questionsCorrect + 1);
    }

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        if (questionsAttempted > 0 || timerOver) {
            let score = parseInt((questionsCorrect * 100) / deck.qcount, 0);
            recordAttempt({
                viewedPercentage: Math.min(
                    100,
                    parseInt((questionsAttempted * 100) / deck.qcount, 0)
                ),
                completion: score >= deck.passingPercentage,
                pointsDelta:
                    score && deck.points
                        ? Math.max(
                              (score * deck.points) / 100 -
                                  (deck.currentState.points || 0),
                              0
                          )
                        : 0,
                score: score,
                deckIsFinalExam: deck.isFinal,
                showEnding: questionsAttempted >= deck.qcount || timerOver,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionsAttempted, timerOver]);

    const busted = deck?.currentState?.viewAttempts >= deck?.attemptsAllowed && parseInt(deck?.attemptsAllowed) !== 0;
    // ========================= Render Function =================================
    return (
        <Div
            fluid
            ht={
                isPreview || startScreen
                    ? "100%"
                    : `calc(100vh - ${
                          isPortrait
                              ? /iPad|iPhone|iPod/.test(navigator.userAgent)
                                  ? 195
                                  : 155
                              : 125
                      }px)`
            }
            centered
            center-align
            noOverflow={isPreview}
            className="fullscreen-target"
            relative
        >
            {startScreen ? (
                <Div
                    absolute
                    fluid
                    fullht
                    style={{ top: 0, left: 0 }}
                    charcoal
                    superpad
                    flex
                    noOverflow
                >
                    <Div huge midnight basepad>
                        Are you ready to start this assessment?
                    </Div>
                    <Div flex column gapped fluid basepad large>
                        <Div flex spaceBetween fluid>
                            <Div uppercase altText>
                                Questions
                            </Div>
                            <Div wd="100px" center-align>
                                {deck?.qcount}
                            </Div>
                        </Div>
                        <Div flex spaceBetween fluid>
                            <Div uppercase altText>
                                Pass Mark
                            </Div>
                            <Div wd="100px" center-align>
                                {deck?.passingPercentage}%
                            </Div>
                        </Div>
                        {deck?.accessTime && deck?.accessTime > 0 && (
                            <Div flex spaceBetween fluid>
                                <Div uppercase altText>
                                    Time Limit
                                </Div>
                                <Div wd="100px" center-align>
                                    {deck?.accessTime} secs
                                </Div>
                            </Div>
                        )}
                        {deck?.attemptsAllowed && parseInt(deck?.attemptsAllowed) > 0 ?  (
                            <Div flex spaceBetween fluid>
                                <Div uppercase altText>
                                    Attempt
                                </Div>
                                <Div wd="100px" center-align>
                                    {deck?.currentState?.viewAttempts + 1 || 1}/
                                    {deck?.attemptsAllowed}
                                </Div>
                            </Div>
                        ): <Div />}
                        {!busted && (
                            <Div gapTop fluid>
                                <Button
                                    fluid
                                    size="big"
                                    primary
                                    content="Start Now"
                                    onClick={() => setStartScreen(false)}
                                />
                            </Div>
                        )}
                    </Div>
                    {busted ? (
                        <Div danger large basepad>
                            P.S. You have exceeded the allowed attempts. Please
                            contact your administrator for a remedial course of
                            action.
                        </Div>
                    ) : (
                        <Div italics basepad half>
                            P.S. You have to do the assessment in one go. Please
                            do not press back or refresh while doing the
                            assessment.
                        </Div>
                    )}
                </Div>
            ) : (
                <Div fluid fullht>
                    <SlideLayout
                        title={title}
                        subtitle={subtitle}
                        image={slideData?.data?.image}
                        sequence={slideData?.slideSeq}
                        colors={deck?.colors}
                        backgroundImage={deck?.backgroundImage}
                        isPreview={isPreview}
                        accessTime={deck?.accessTime}
                        setTimerOver={setTimerOver}
                    >
                        {SlideComponent && (
                            <SlideComponent
                                {...slideData}
                                deckId={deck._id}
                                setPageNumber={setPageNumber}
                                pageNumber={pageNumber}
                                trackData={trackData}
                            />
                        )}
                    </SlideLayout>
                    {!isPreview && (
                        <DeckToolbar
                            {...{
                                deck,
                                pageNumber,
                                setPageNumber,
                                numPages:
                                    deck.qcount == null || deck.qcount === 0
                                        ? deck.content.length
                                        : deck.qcount,
                                navConfig: { next: false, prev: false },
                            }}
                        />
                    )}
                </Div>
            )}
        </Div>
    );
}
