import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Input, Image, Button } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFeatures } from "flagged";
import shortid from "shortid";

import axios from "axios";
import { baseUrl } from "@api/apiV6";
import { login } from "@reducers/authSlice";

import { Div } from "@components/Generics.react";
import AuthLayout from "@layouts/AuthLayout.react";
import { settings } from "../../config/settings/app.settings";

export default function CodeLogin(props) {
    const { team } = useParams();

    const [code, setCode] = useState("");

    const features = useFeatures();
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const { t } = useTranslation("common");

    const tryRegister = async () => {
        const existingUser = window.localStorage.getItem("guestUsername");
        if (existingUser) {
            doLogin(existingUser);
        } else {
            const username = shortid.generate();
            const nameSplit = ["Guest", "User"];
            let formData = new FormData();
            formData.append("username", username);
            formData.append("password", username);
            formData.append("email", `${username}@guest.quodeck.com`);
            formData.append("first_name", nameSplit[0]);
            formData.append("last_name", nameSplit[1] || " ");
            formData.append("team", team == null ? "Company" : team);
            formData.append("active", true);

            try {
                const signupResponse = await axios({
                    method: "post",
                    url: `${baseUrl}api/v3/auth/register`,
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                });
                if (signupResponse.status === 201) {
                    window.localStorage.setItem("guestUsername", username);
                    if (features.tenanting.teamCourses) {
                        await axios({
                            method: "post",
                            url: `${baseUrl}api/v3/users/${signupResponse.data._id}/team_enrollment`,
                        });
                    }
                    doLogin(username);
                }
            } catch (error) {
                console.log(error);
                setError(error.response.data.message);
            }
        }
    };

    async function doLogin(uname) {
        let loginData = new FormData();
        loginData.append("username", uname);
        loginData.append("password", uname);
        const response = await axios({
            method: "post",
            url: `${baseUrl}api/v3/auth/sign_in`,
            data: loginData,
            headers: { "Content-Type": "multipart/form-data" },
        });
        await dispatch(
            login({
                user: response.data,
                token: response.headers["access-token"],
            })
        );
        window.localStorage.setItem("firstLogin", "done");
        if (team != null)
            window.localStorage.setItem("team", response.data.team);
        setTimeout(() => {
            window.location.href = `/c/${code}`;
        }, 1000);
    }

    const registrationBox = (
        <Div fluid superpad flex centered maxWd="480px">
            <Div fluid white fullHt superpad rounded>
                <Div massive altText fluid center-align gutter>
                    {t("auth.enterCode")}
                </Div>
                {error && (
                    <Div padded fluid danger gutter>
                        {error}
                    </Div>
                )}
                <Div fluid gutter huge center-align>
                    <Input fluid>
                        <input
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            style={{ textAlign: "center" }}
                        />
                    </Input>
                </Div>
                <Div fluid gutter>
                    <Button
                        fluid
                        primary
                        content="SUBMIT"
                        onClick={tryRegister}
                        disabled={code.length < 9}
                    />
                </Div>
            </Div>
        </Div>
    );

    const { registrationActive, guestsActive } = settings.functionality;
    // ========================= Render Function =================================

    return (
        <AuthLayout
            title={`Register on ${t("appName")}`}
            team={team}
            teamLogin={true}
        >
            <Div fluid flex pageht>
                <Div maxWd={"480px"} centered>
                    <Div centered wd="60%" gutter>
                        <Image
                            fluid
                            src={
                                "/assets/images/configurable/full-logo-inverted.png"
                            }
                        />
                    </Div>
                    {registrationActive && guestsActive ? (
                        <Div fluid>{registrationBox}</Div>
                    ) : (
                        <Div basepad spaced white rounded big center-align>
                            Code based Login Not Available
                        </Div>
                    )}
                </Div>
            </Div>
        </AuthLayout>
    );
}
