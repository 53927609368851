import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFeatures } from "flagged";
import _ from "lodash";
import { toast } from "react-toastify";
import shortid from "shortid";

import {
    useSendRemoveEnrollmentRuleMutation,
    useSendRunEnrollmentRuleMutation,
    useCreateInNotificationMutation,
    useCreateEmailNotificationMutation
} from "@api/apiV6";

import {
    Icon,
    Checkbox,
    Input,
    List,
    Label,
    Modal,
    Tab,
} from "semantic-ui-react";
import { Div, Button } from "@components/Generics.react";
import LearnerTable from "./LearnerTable.react";
import EnrollLearnerModal from "./EnrollLearnerModal.react";
import EnrollRuleModal from "./EnrollRuleModal.react";
import EnrollLearnerOperations from "./EnrollLearnerOperations.react";
import NotifyWrapper from "./NotifyWrapper.react";
import { settings } from "@config/settings/app.settings";
import BuilderHeader from "@components/BuilderHeader.react";
import VersionList from "./VersionList.react";
import ShareWidget from "@components/ShareWidget.react";
import NotificationModal from "@components/NotificationModal.react";
import { notificationConfig } from "@config/notifications/config.notification";

export default function CourseLearners(props) {
    const { course, updateCourse, launchCourse, unlaunchCourse } = props;
    const features = useFeatures();

    const targetUrl = window.location.origin;
    const shortUrl = `${targetUrl}/${
        features.courses.shareExploreLink ? "e" : "c"
    }/${course.identifier}`;

    const [removeEnrollmentRule] = useSendRemoveEnrollmentRuleMutation();
    const [runEnrollmentRule] = useSendRunEnrollmentRuleMutation();
    const [triggerInNotification] = useCreateInNotificationMutation();
    const [triggerEmailNotification] = useCreateEmailNotificationMutation();

    const { t } = useTranslation("common");
    const [showLaunch, setShowLaunch] = useState(true);
    const [showVersions, setShowVersions] = useState(false);
    const [search, setSearch] = useState("");
    const [uploadUserModalOpen, setUploadUserModalOpen] = useState(false);
    const [uploadMultiUserModalOpen, setUploadMultiUserModalOpen] =
        useState(false);
    const [enrollRuleModalOpen, setEnrollRuleModalOpen] = useState(false);
    const [getUserModalOpen, setGetUserModalOpen] = useState(false);
    const [removeUserModalOpen, setRemoveUserModalOpen] = useState(false);
    const [notifyUserModalOpen, setNotifyUserModalOpen] = useState(false);
    const [ruleToRemove, setRuleToRemove] = useState(null);
    const [ruleToRun, setRuleToRun] = useState(null);
    const [notificationModalOpen, setNotificationModalOpen] = useState(false);
    const [messageTitle, setMessageTitle] = useState("");
    const [messageContent, setMessageContent] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const isExam = course.course_type === "exam";
    const cType = isExam ? "exam" : "course";

    async function toggleLaunchCourse() {
        if (course.launch) {
            await unlaunchCourse({
                id: course._id,
            }).unwrap();
            setTimeout(() => setShowVersions(true), 1000);
            toast(t(`builder.${cType}.unlaunched`));
        } else {
            await launchCourse({
                id: course._id,
            }).unwrap();
            toast(t(`builder.${cType}.launched`));
        }
    }

    async function togglePublicizeCourse() {
        if (course.publicized) {
            await updateCourse({
                id: course._id,
                publicized: false,
            }).unwrap();
            toast(t(`builder.${cType}.unpublicized`));
        } else {
            await updateCourse({
                id: course._id,
                publicized: true,
                identifier:
                    course.identifier == null
                        ? shortid.generate()
                        : course.identifier,
            }).unwrap();
            toast(t(`builder.${cType}.publicized`));
        }
    }

    function renderCourseRules() {
        const actionIconStyle = {
            float: "right",
            lineHeight: "2em",
            cursor: "pointer",
            height: "2rem",
        };
        return course?.rules.map((rule, idx) => (
            <List.Item
                key={`course-rule-${idx}`}
                style={{
                    background: "#cccccc",
                    padding: "3px",
                    marginBottom: "3px",
                }}
            >
                {_.map(_.keys(rule.criteria), (key, idy) => (
                    <Label key={`course-rule-elm-${idy}`}>
                        {rule.criteria[key]}
                    </Label>
                ))}
                <Icon
                    name="close"
                    style={actionIconStyle}
                    onClick={() => setRuleToRemove(idx)}
                    title="Remove Course Rule"
                />
                <Icon
                    name="play"
                    style={actionIconStyle}
                    onClick={() => setRuleToRun(idx)}
                    title="Run Course Rule"
                />
            </List.Item>
        ));
    }

    function removeRule(idx) {
        removeEnrollmentRule({
            id: course?._id,
            criteria_id: course?.rules[idx]._id,
        });
        setRuleToRemove(null);
    }

    function runRule(idx) {
        runEnrollmentRule({
            id: course?._id,
            criteria_id: course?.rules[idx]._id,
        });
        setRuleToRun(null);
    }

    function handleConfirmRemove() {
        if (ruleToRemove !== null) {
            removeRule(ruleToRemove);
        }
    }

    function handleRunRule() {
        if (ruleToRun !== null) {
            runRule(ruleToRun);
        }
    }

    const onSelectRowsChange = (rows) => {
        setSelectedRows(rows);
    };

    const handleNotifications = (title, content) => {
        setMessageTitle(title);
        setMessageContent(content);

        if (notificationConfig.broadcastMessage.inAppNotification || notificationConfig.broadcastMessage.emailNotification) {
            if (notificationConfig.broadcastMessage.inAppNotification) {
                selectedRows.forEach(userId => {
                    let message = _.pick(
                        notificationConfig.broadcastMessage,
                        "type",
                        "title",
                        "description",
                        "link"
                    );
                    message.title = messageTitle;
                    message.description = messageContent;
                    triggerInNotification({
                        _id: userId,
                        message: message,
                    });
                });
            }
            if (notificationConfig.broadcastMessage.emailNotification) {
                selectedRows.forEach(userId => {
                    triggerEmailNotification({
                        _id: userId,
                        route: notificationConfig.broadcastMessage.route,
                        message: {
                            subject: messageTitle,
                            body: messageContent,
                        },
                        emailType: "broadcast_message",
                    });
                });
            }
        }

        setNotificationModalOpen(false)
        setMessageTitle("");
        setMessageContent("");
    };

    function copyTextToClipboard(text) {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                toast("Text copied to clipboard");
            })
            .catch((err) => {
                console.error("Failed to copy text: ", err);
            });
    }

    const panes = [
        {
            menuItem: t("builder.course.launchHeader"),
            render: () => (
                <Tab.Pane>
                    <Div
                        fluid
                        basepad
                        danger={!course.launch}
                        success={course.launch}
                        snug
                        snubbed
                    >
                        <Div
                            clickable
                            onClick={() => setShowLaunch(!showLaunch)}
                        >
                            <Div float-right large>
                                <Icon
                                    name={
                                        showLaunch
                                            ? "chevron down"
                                            : "chevron up"
                                    }
                                />
                            </Div>
                            <Div bold big>
                                {t(`builder.${cType}.status`)}:{" "}
                                {course.launch
                                    ? t(`builder.${cType}.published`)
                                    : t(`builder.${cType}.unpublished`)}
                            </Div>
                            <Div italics gutter>
                                {course.launch
                                    ? t(`builder.${cType}.publishedHelp`)
                                    : t(`builder.${cType}.unpublishedHelp`)}
                            </Div>
                        </Div>
                        {showLaunch && (
                            <Div vapor basepad snubbed>
                                <Checkbox
                                    toggle
                                    label={t(`builder.${cType}.launch`)}
                                    checked={course.launch}
                                    onChange={toggleLaunchCourse}
                                />
                                <br />
                                <br />
                                <Div
                                    white
                                    small
                                    slightShadow
                                    basepad
                                    snubbed
                                    gapTop
                                >
                                    <Div big bold>
                                        {t(
                                            `builder.${cType}.${
                                                course.launch
                                                    ? "unLaunch"
                                                    : "doLaunch"
                                            }.header`
                                        )}
                                    </Div>
                                    <Div>
                                        {t(
                                            `builder.${cType}.${
                                                course.launch
                                                    ? "unLaunch"
                                                    : "doLaunch"
                                            }.message`
                                        )}
                                    </Div>
                                    <ul>
                                        {_.map(
                                            t(
                                                `builder.${cType}.${
                                                    course.launch
                                                        ? "unLaunch"
                                                        : "doLaunch"
                                                }.points`
                                            ).split(`||`),
                                            (pt, idx) => {
                                                return (
                                                    <li
                                                        key={`launch-pt-${idx}`}
                                                    >
                                                        {pt}
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                </Div>
                            </Div>
                        )}
                        <br />
                        {course.launch && (
                            <Div fluid basepad smoke snubbed>
                                <Checkbox
                                    toggle
                                    label={t(`builder.${cType}.teamit`)}
                                    checked={course.publicized}
                                    onChange={togglePublicizeCourse}
                                />
                                {course.publicized && (
                                    <Div gapTop>
                                        <Div gapBottom>
                                            <Input
                                                fluid
                                                action={{
                                                    icon: (
                                                        <Icon
                                                            name="copy"
                                                            link
                                                            onClick={() =>
                                                                copyTextToClipboard(
                                                                    shortUrl
                                                                )
                                                            }
                                                        />
                                                    ),
                                                    color: "teal",
                                                }}
                                                value={shortUrl}
                                            />
                                        </Div>
                                        <Div flex spaceBetween>
                                            <Div bold padSides>
                                                Share on:
                                            </Div>
                                            <Div>
                                                <ShareWidget
                                                    url={shortUrl}
                                                    subject={course.name}
                                                />
                                            </Div>
                                        </Div>
                                    </Div>
                                )}
                            </Div>
                        )}
                        <br />
                        <VersionList
                            course={course}
                            showVersions={showVersions}
                        />
                    </Div>
                </Tab.Pane>
            ),
        },
        features.courses.autoEnrollment && {
            menuItem: t("builder.course.enruleHeader"),
            render: () => (
                <Tab.Pane>
                    <Div ivory fluid snubbed noOverflow>
                        <Div fluid basepad ash snug snubbed>
                            <Div bold big gutter>
                                {t(`builder.${cType}.enruleHeader`)}:
                            </Div>
                            <Div gapBottom>
                                {t(`builder.${cType}.enruleBody1`)}
                            </Div>
                            <Div gutter>
                                {t(`builder.${cType}.enruleBody2`)}
                                <br />
                                <List>{renderCourseRules()}</List>
                            </Div>
                            <Button
                                primary
                                fluid
                                content={t(`builder.${cType}.addEnrule`)}
                                onClick={() => setEnrollRuleModalOpen(true)}
                            />
                        </Div>
                    </Div>
                </Tab.Pane>
            ),
        },
    ].filter(Boolean);

    // ========================= Render Function =================================
    return (
        <Div fluid fullht padTop flex noWrap spaceAround>
            <Div fullht wd="calc(100% - 500px)" basepad>
                <BuilderHeader
                    image={course?.image}
                    header={`${t(`builder.${cType}.headers.launch.header`)}: ${
                        course?.name
                    }`}
                    description={t(`builder.${cType}.headers.launch.help`)}
                />
                <Div ht="calc(100% - 150px)" autoOverflowY gapTop>
                    <Tab panes={panes} />
                </Div>
            </Div>
            <Div fullht wd="500px" basepad>
                <Div
                    basepad
                    ivory
                    fluid
                    fullht
                    rounded
                    slightShadow
                    autoOverflowY
                >
                    <Div bold big uppercase gutter>
                        {t(`builder.${cType}.manageLearners`)}
                    </Div>
                    <Div fluid gapTop flex gapped spaceBetween gutter>
                        <Input
                            icon="search"
                            placeholder={t(`components.search`)}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <Div>
                            <Button
                                primary
                                content={t(`builder.${cType}.invite`)}
                                onClick={() => setUploadUserModalOpen(true)}
                            />
                            <Button
                                primary
                                content={t(`builder.dic.upload`)}
                                onClick={() =>
                                    setUploadMultiUserModalOpen(true)
                                }
                            />
                        </Div>
                    </Div>
                    {selectedRows.length > 0 && <Div fluid gutter>
                        <Button
                            fluid
                            primary
                            icon="sticky note"
                            labelPosition="left"
                            content="Message Selected Users"
                            onClick={() => setNotificationModalOpen(true)}
                        />
                        <NotificationModal
                            open={notificationModalOpen}
                            onClose={() => setNotificationModalOpen(false)}
                            messageTitle={messageTitle}
                            messageContent={messageContent}
                            onChangeTitle={setMessageTitle}
                            onChangeContent={setMessageContent}
                            onConfirm={handleNotifications}
                        />
                    </Div>}
                    {course.learnerCount > settings.largeUserbase ? (
                        <Div
                            white
                            fluid
                            center-align
                            superpad
                            flex
                            column
                            gapped
                            big
                            slightShadow
                        >
                            <Div gutter>{t(`builder.${cType}.tooHigh`)}</Div>
                            <Button
                                primary
                                content={t(`builder.${cType}.checkEnrolled`)}
                                onClick={() => setGetUserModalOpen(true)}
                            />
                            <Button
                                primary
                                content={t(`builder.${cType}.unenrollUser`)}
                                onClick={() => setRemoveUserModalOpen(true)}
                            />
                            <Button
                                primary
                                content={t(`builder.${cType}.messageUser`)}
                                onClick={() => setNotifyUserModalOpen(true)}
                            />
                        </Div>
                    ) : (
                        <LearnerTable
                            course={course}
                            updateCourse={updateCourse}
                            search={search}
                            onSelectRowsChange={onSelectRowsChange}
                        />
                    )}
                </Div>
            </Div>
            <EnrollLearnerModal
                course={course}
                isOpen={uploadUserModalOpen}
                setOpen={setUploadUserModalOpen}
                isMultiOpen={uploadMultiUserModalOpen}
                setMultiOpen={setUploadMultiUserModalOpen}
            />
            <EnrollRuleModal
                course={course}
                isOpen={enrollRuleModalOpen}
                setOpen={setEnrollRuleModalOpen}
            />
            <EnrollLearnerOperations
                course={course}
                getUser={getUserModalOpen}
                setGetUser={setGetUserModalOpen}
                removeUser={removeUserModalOpen}
                setRemoveUser={setRemoveUserModalOpen}
            />
            <NotifyWrapper
                course={course}
                notifyUser = {notifyUserModalOpen}
                setNotifyUser = {setNotifyUserModalOpen}
            />
            <Modal
                open={ruleToRemove !== null || ruleToRun !== null}
                onClose={() => {
                    setRuleToRemove(null);
                    setRuleToRun(null);
                }}
                size="small"
            >
                <Modal.Header>
                    {ruleToRemove !== null
                        ? "Confirm Removal"
                        : "Confirm Running"}
                </Modal.Header>
                <Modal.Content>
                    <p>
                        {ruleToRemove !== null
                            ? "Are you sure you want to remove this rule?"
                            : "Are you sure you want to run this rule?"}
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    {ruleToRemove !== null && (
                        <Button negative onClick={handleConfirmRemove}>
                            Yes, Remove
                        </Button>
                    )}
                    {ruleToRun !== null && (
                        <Button positive onClick={handleRunRule}>
                            Yes, Run
                        </Button>
                    )}
                    <Button
                        onClick={() => {
                            setRuleToRemove(null);
                            setRuleToRun(null);
                        }}
                    >
                        Cancel
                    </Button>
                </Modal.Actions>
            </Modal>
        </Div>
    );
}
