import React, { useState } from "react";
import _ from "lodash";
import {
    Modal,
    List,
    Segment,
    Grid,
    Icon,
    Progress,
    Label,
} from "semantic-ui-react";
import { Div, Button } from "@components/Generics.react";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";
import FileSaver from "file-saver";
import { settings } from "@config/settings/app.settings";
import { useCreateUserMutation, useCreateInNotificationMutation, useCreateEmailNotificationMutation } from "@api/apiV6";
import { notificationConfig } from "@config/notifications/config.notification";
import { toast } from "react-toastify";

// Utility functions
const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+.[^\s@]+$/;
    return re.test(email);
};

const checkPassword = (password) => {
    const passwd = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/;
    return passwd.test(password);
};

const validateLoginDetails = (username, name, email, password, stage) => {
    const errors = [];

    if (!username) errors.push("Username can't be blank");
    if (!name) errors.push("Name can't be blank");
    if (!validateEmail(email)) errors.push("Email can't be malformed");
    if (!checkPassword(password)) errors.push("Password is not strong enough");
    if (isNaN(Number(stage))) errors.push("Stage should be a number");

    return errors;
};

const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
        return `${days} day${days > 1 ? "s" : ""}`;
    } else if (hours > 0) {
        return `${hours} hour${hours > 1 ? "s" : ""}`;
    } else if (minutes > 0) {
        return `${minutes} minute${minutes > 1 ? "s" : ""}`;
    } else {
        return `${seconds} second${seconds > 1 ? "s" : ""}`;
    }
};

const processRow = async (row, createUser, triggerInNotification, triggerEmailNotification) => {
    const { username = '', name = '', company = '', email = '', password = '', stage = '', team = '', manager = '', trainers = '' } = row;
    const nameSplit = name.split(" ");
    const trimmedRow = {
        username: username.trim(),
        name: nameSplit[0] + " " + (nameSplit[1] || ""),
        first_name: nameSplit[0],
        last_name: nameSplit[1] || " ",
        org: company,
        email: email.trim().toLowerCase(),
        password: password.trim(),
        active: true,
        stage: stage,
        team: team,
        manager: manager.trim(),
        trainers: trainers.split(',').map(t => t.trim()),
    };
    const validationErrors = validateLoginDetails(trimmedRow.username, trimmedRow.name, trimmedRow.email, trimmedRow.password, trimmedRow.stage);
    const additionalKeys = _.filter(_.keys(row), key => ![].includes(key));
    const additionalDetails = _.pick(row, additionalKeys);

    if (validationErrors.length > 0) {
        return {
            row: { ...trimmedRow, ...additionalDetails },
            status: false,
            result: validationErrors.join(", "),
        };
    }

    try {
        const userCreated = await createUser({
            name: trimmedRow.name,
            username: trimmedRow.username,
            first_name: trimmedRow.first_name,
            last_name: trimmedRow.last_name,
            email: trimmedRow.email,
            password: trimmedRow.password,
            manager: trimmedRow.manager,
            additionalDetails
        });

        if (notificationConfig.addUser.inAppNotification || notificationConfig.addUser.emailNotification) {
            if (notificationConfig.addUser.inAppNotification) {
                let message = _.pick(
                    notificationConfig.addUser,
                    "type",
                    "title",
                    "description",
                    "link"
                );
                message.created_at = new Date();
                await triggerInNotification({
                    _id: userCreated?.data?._id,
                    message: message,
                });
            }
            if (notificationConfig.addUser.emailNotification) {
                await triggerEmailNotification({
                    _id: userCreated?.data?._id,
                    route: notificationConfig.addUser.route,
                    emailType: "user_creation",
                });
            }
        }
        if (userCreated) {
            toast("User Created");
        }

        return {
            row: { ...trimmedRow, ...additionalDetails },
            status: true,
            result: "created user",
        };

    } catch (err) {
        console.error(err);
        return {
            row: { ...trimmedRow, ...additionalDetails },
            status: false,
            result: err.message,
        };
    }
};

const processFile = async (xlsFile, createUser, triggerInNotification, triggerEmailNotification, setLoading, setXlsFile, setProcessingStatus, setProgress, setEstimatedTime, setSuccessCount, setFailureCount) => {
    setLoading(true);
    const reader = new FileReader();

    reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[1]]);
        const totalUsers = sheetData.length;
        let processedUsers = 0;
        let startTime = Date.now();

        const results = await Promise.allSettled(sheetData.map(row => processRow(row, createUser, triggerInNotification, triggerEmailNotification)));
        processedUsers = results.length;

        const elapsedTime = (Date.now() - startTime) / 1000;
        const avgTimePerUser = elapsedTime / processedUsers;
        const remainingUsers = totalUsers - processedUsers;
        const estimatedTimeLeft = remainingUsers * avgTimePerUser;

        setProcessingStatus(results.map(result => result.value));
        setProgress(100);
        setEstimatedTime(formatTime(estimatedTimeLeft));

        const successCount = results.filter(result => result.status === "fulfilled").length;
        const failureCount = results.length - successCount;
        setSuccessCount(successCount);
        setFailureCount(failureCount);

        setLoading(false);
        setXlsFile(null);
    };
    reader.readAsBinaryString(xlsFile);
};

// Main component
const UploadUsersModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [xlsFile, setXlsFile] = useState(null);
    const [processingStatus, setProcessingStatus] = useState([]);
    const [progress, setProgress] = useState(0);
    const [estimatedTime, setEstimatedTime] = useState(0);
    const [successCount, setSuccessCount] = useState(0);
    const [failureCount, setFailureCount] = useState(0);
    const [createUser] = useCreateUserMutation();
    const [triggerInNotification] = useCreateInNotificationMutation();
    const [triggerEmailNotification] = useCreateEmailNotificationMutation();
    const [showProgress, setShowProgress] = useState(false);

    const handleXLSFileLoad = (e) => {
        setXlsFile(e.target.files[0]);
    };

    const processFileAndEnroll = () => {
        setShowProgress(true);
        processFile(xlsFile, createUser, triggerInNotification, triggerEmailNotification, setLoading, setXlsFile, setProcessingStatus, setProgress, setEstimatedTime, setSuccessCount, setFailureCount);
    }

    const downloadCSV = (rows, fileName) => {
        const header = ["username", "name", "email", "password", "active", "stage", "team", "manager", "trainers"];
        const csvContent = [header.join(",")]
            .concat(rows.map(row => header.map(key => row.row[key] || "").join(",")))
            .join("\r\n");

        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
        FileSaver.saveAs(blob, fileName);
    };

    const downloadSuccess = () => {
        const processed = _.filter(processingStatus, row => row.status);
        downloadCSV(processed, "success.csv");
    };

    const downloadFailure = () => {
        const unprocessed = _.filter(processingStatus, row => !row.status);
        downloadCSV(unprocessed, "failure.csv");
    };

    const processed = _.filter(processingStatus, row => row.status);
    const unprocessed = _.filter(processingStatus, row => !row.status);
    const { t } = useTranslation("common");
    return (
        <Div>
            <Modal.Content>
                <Segment basic style={{ background: "#f5f5f5" }}>
                    {showProgress && (
                        <Segment basic>
                            <Progress
                                percent={progress}
                                indicating
                                progress
                                style={{
                                    background: `linear-gradient(90deg, rgba(66, 133, 244, 1) 0%, rgba(219, 68, 55, 1) 50%, rgba(15, 157, 88, 1) 100%)`,
                                    height: "20px",
                                }}
                            />
                            <p style={{ fontSize: "1.2em", margin: "10px 0" }}>
                                Estimated time left: {estimatedTime}
                            </p>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <Label color="green" style={{ fontSize: "1.2em" }}>
                                    Success: {successCount}
                                </Label>
                                <Label color="red" style={{ fontSize: "1.2em" }}>
                                    Failed: {failureCount}
                                </Label>
                            </div>
                        </Segment>
                    )}
                    <Segment.Group horizontal>
                        {!loading && processingStatus.length === 0 && (
                            <Segment raised>
                                <h3>Step 1: Download Sample File</h3>
                                <Button
                                    primary
                                    fluid
                                    content="Sample File"
                                    href={
                                        settings.baseUrl.endsWith("/")
                                            ? settings.baseUrl + "public/samples/register_user_upload_format.xlsx"
                                            : settings.baseUrl + "/public/samples/register_user_upload_format.xlsx"
                                    }
                                />
                            </Segment>
                        )}
                        <Segment raised>
                            {!loading && processingStatus.length === 0 && (
                                <div>
                                    <h3>Step 2: Upload Excel File with Users</h3>
                                    <input
                                        type="file"
                                        onChange={handleXLSFileLoad}
                                        accept=".xlsx, .xls"
                                    />
                                </div>
                            )}

                            {processingStatus.length !== 0 && (
                                <Segment basic style={{ background: "#f5f5f5" }}>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={8}>
                                                <h3>
                                                    <Icon
                                                        name="download"
                                                        circular
                                                        inverted
                                                        color="yellow"
                                                        onClick={downloadSuccess}
                                                        size="small"
                                                        style={{ cursor: "pointer" }}
                                                    />{" "}
                                                    Successfully Created Users
                                                </h3>
                                                <List
                                                    divided
                                                    style={{
                                                        width: "100%",
                                                        fontSize: "1.15em",
                                                        maxHeight: "22em",
                                                        overflow: "auto",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    {processed.map((row, idx) => (
                                                        <List.Item key={`upload-item-${idx}`}>
                                                            {row.row.email}
                                                        </List.Item>
                                                    ))}
                                                </List>
                                            </Grid.Column>
                                            <Grid.Column width={8}>
                                                <h3>
                                                    <Icon
                                                        name="download"
                                                        circular
                                                        inverted
                                                        color="yellow"
                                                        onClick={downloadFailure}
                                                        size="small"
                                                        style={{ cursor: "pointer" }}
                                                    />{" "}
                                                    Unsuccessful Users
                                                </h3>
                                                <List
                                                    divided
                                                    style={{
                                                        width: "100%",
                                                        fontSize: "1.15em",
                                                        maxHeight: "22em",
                                                        overflow: "auto",
                                                    }}
                                                >
                                                    {unprocessed.map((row, idx) => (
                                                        <List.Item key={`not-upload-item-${idx}`}>
                                                            {row.row.email === "" ? "Unknown Email" : row.row.email}: {row.result}
                                                        </List.Item>
                                                    ))}
                                                </List>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            )}
                        </Segment>
                    </Segment.Group>
                </Segment>


                <Div basepad vapor gutter>
                    <Div bold>
                        {t("auth.registration.bulkGuidelines")}
                    </Div>
                    <ul>
                        <li>
                            <b>
                                {t("auth.registration.usernameLabel")}:
                            </b>{" "}
                            {t("auth.registration.usernameHelp")}
                        </li>
                        <li>
                            <b>{t("auth.registration.nameLabel")}:</b>{" "}
                            {t("auth.registration.nameHelp")}
                        </li>
                        <li>
                            <b>{t("auth.registration.password")}</b>{" "}
                            {t("auth.registration.passwordHelp")}
                        </li>
                    </ul>
                </Div>
                <Div small rimmed>
                    {processingStatus.length === 0 && (
                        <Button
                            secondary
                            loading={loading}
                            size="normal"
                            disabled={xlsFile === null}
                            content={"Create Users and AutoEnroll"}
                            onClick={processFileAndEnroll}
                        />
                    )}
                </Div>
            </Modal.Content>
        </Div>
    );
};

export default UploadUsersModal;
