import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { toast } from "react-toastify";
import "@styles/editor.scss";

import { useDuplicateDeckMutation } from "@api/apiV6";
import { Menu, Icon, Tab, Dropdown, Confirm, Image } from "semantic-ui-react";
import { Div, Button } from "@components/Generics.react";
import InlineInput from "@components/InlineInput.react";
import InlineTextarea from "@components/InlineTextarea.react";

import PreviewLayout from "@layouts/PreviewLayout.react";
import { WrapperList } from "@schemas/wrapperSchema";
import { text_truncate } from "@utilities/helpers";
import { deckFormats } from "@schemas/deckFormats";
import { NavLink } from "react-router-dom";
import CourseContentModal from "./CourseContentModal.react";
import BuilderHeader from "@components/BuilderHeader.react";
import ImageUploader from "@components/ImageUploader.react";

export default function CourseContent(props) {
    const {
        course,
        updateCourse,
        updateDeck,
        deleteDeck,
        moveDeck,
        deleteTopic,
    } = props;
    const user = useSelector((state) => state.auth.user);

    const [activeTab, setActiveTab] = useState(1);
    const [newModalOpen, setNewModalOpen] = useState(false);
    const [deleteDeckConfirm, setDeleteDeckConfirm] = useState(false);
    const [deleteTopicConfirm, setDeleteTopicConfirm] = useState(false);
    const [targetDeck, setTargetDeck] = useState(null);
    const [duplicateDeck] = useDuplicateDeckMutation();
    const [imgEditorOpen, setImgEditorOpen] = useState(false);
    const { t } = useTranslation("common");

    const isExam = course.course_type === "exam";
    const cType = isExam ? "exam" : "course";

    async function doDuplicate(id) {
        await duplicateDeck(id).unwrap();
        toast(`Deck Duplicated`);
    }

    async function addTopic() {
        const locations = [...course.locations];
        locations.push({
            name: t(`builder.${cType}.newTopic`),
            description: t(`builder.${cType}.newDescription`),
            image: null,
            sequence: locations.length,
            contentList: [],
        });
        await updateCourse({
            id: course._id,
            locations: locations,
        }).unwrap();
        toast(t(`builder.${cType}.topicAdded`));
    }

    async function handleTopicUpdate(target, data, topicId) {
        let locations = _.cloneDeep(course.locations);
        let thisLocation = _.find(locations, { _id: topicId });
        if (thisLocation) {
            thisLocation[target] = data;
            await updateCourse({
                id: course._id,
                locations: locations,
            }).unwrap();
            toast(t(`builder.${cType}.topicUpdated`));
        }
    }
    async function moveTopic(topicId, direction) {
        let locations = _.cloneDeep(course.locations);
        const locIndex = _.findIndex(locations, { _id: topicId });
        const loc2Index = direction === "up" ? locIndex - 1 : locIndex + 1;
        if (locIndex) {
            const temp = locations[locIndex]; // Store the value at index1 in a temporary variable.
            locations[locIndex] = locations[loc2Index]; // Set the value at index1 to the value at loc2Index.
            locations[loc2Index] = temp;
            locations[locIndex].sequence = locIndex;
            locations[loc2Index].sequence = loc2Index;
            await updateCourse({
                id: course._id,
                locations: locations,
            }).unwrap();
            toast(t(`builder.${cType}.topicMoved`));
        }
    }
    async function doDeleteTopic() {
        await deleteTopic({
            id: course.locations[activeTab - 1]._id,
            courseId: course._id,
        });
        setDeleteTopicConfirm(false);
        toast(t(`builder.${cType}.topicDeleted`));
    }

    async function renameDeck(id, name) {
        await updateDeck({
            id: id,
            name: name,
        }).unwrap();
        toast(t(`builder.deck.renamed`));
    }

    async function doMoveDeck(deckId, direction) {
        await moveDeck({
            id: deckId,
            direction: direction,
        }).unwrap();
        toast(t(`builder.deck.moved`));
    }
    async function moveDeckToTopic(deckId, topicId) {
        await updateDeck({
            id: deckId,
            topic_id: topicId,
            course_id: course._id,
        }).unwrap();
        toast(t(`builder.deck.moved`));
    }

    function attemptDeleteDeck(deckId) {
        setTargetDeck(deckId);
        setDeleteDeckConfirm(true);
    }

    async function doDeleteDeck() {
        await deleteDeck(targetDeck).unwrap();
        setTargetDeck(null);
        setDeleteDeckConfirm(false);
        toast(t(`builder.deck.deleted`));
    }

    function handleTabChange(e, { activeIndex }) {
        if (activeIndex < course.locations.length + 1 && activeIndex !== 0)
            setActiveTab(activeIndex);
    }

    function decksPanel(topic) {
        return _.map(
            _.orderBy(topic.contentList, ["sequence"], ["asc"]),
            (deck, idy) => {
                const movableTopics = _.filter(
                    course.locations,
                    (t) =>
                        t._id !== topic._id &&
                        t.contentList.length <= user.license.maxDecks
                );
                return (
                    <Div
                        flex
                        spaceBetween
                        white
                        snug
                        rimmed
                        key={`content-piece-${idy}`}
                    >
                        <Div wd="calc(100% - 75px)" flex flexStart gapped>
                            <Div half compact uppercase tiny wd="100px">
                                {
                                    _.find(deckFormats, {
                                        readerType: deck.readerType,
                                    })?.name
                                }
                            </Div>
                            <Div wd="calc(100% - 110px)">
                                <InlineInput
                                    text={deck.name}
                                    handleSave={(data) => {
                                        renameDeck(deck._id, data);
                                    }}
                                    showHelp={false}
                                />
                            </Div>
                        </Div>
                        <Div rightAlign clickable>
                            <NavLink
                                to={`/builder/editor/${deck.readerType}/${deck._id}/${deck.name}`}
                            >
                                <Div
                                    primary
                                    compact
                                    inline
                                    small
                                    hoverBold
                                    snubbed
                                >
                                    {t(`components.edit`)}
                                </Div>
                            </NavLink>
                            <Dropdown icon="ellipsis vertical" direction="left">
                                <Dropdown.Menu position="right">
                                    <Dropdown.Item
                                        icon="chevron up"
                                        text={t(`builder.deck.moveUp`)}
                                        disabled={idy === 0}
                                        onClick={() =>
                                            doMoveDeck(deck._id, "up")
                                        }
                                    />
                                    <Dropdown.Item
                                        icon="chevron down"
                                        text={t(`builder.deck.moveDown`)}
                                        disabled={
                                            idy === topic.contentList.length - 1
                                        }
                                        onClick={() =>
                                            doMoveDeck(deck._id, "down")
                                        }
                                    />
                                    <Dropdown
                                        item
                                        text={t(`builder.deck.moveTo`)}
                                        disabled={movableTopics.length === 0}
                                    >
                                        <Dropdown.Menu position="left">
                                            {_.map(movableTopics, (t, idx) => (
                                                <Dropdown.Item
                                                    key={`Topic-move-${idx}`}
                                                    text={t.name}
                                                    onClick={() =>
                                                        moveDeckToTopic(
                                                            deck._id,
                                                            t._id
                                                        )
                                                    }
                                                />
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown.Divider />
                                    <Dropdown.Item
                                        icon="copy"
                                        text={t(`builder.deck.duplicate`)}
                                        onClick={() => doDuplicate(deck._id)}
                                    />
                                    <Dropdown.Item
                                        icon="trash"
                                        text={t(`builder.deck.delete`)}
                                        onClick={() =>
                                            attemptDeleteDeck(deck._id)
                                        }
                                    />
                                </Dropdown.Menu>
                            </Dropdown>
                        </Div>
                    </Div>
                );
            }
        );
    }

    function topicPanel(topic, idx, showTop = true) {
        return (
            <Tab.Pane key={`folder-panel-${idx}`}>
                {showTop && (
                    <Div big bold uppercase gapBottom>
                        <Div float-right clickable>
                            <Icon
                                name="chevron up"
                                onClick={() => moveTopic(topic._id, "up")}
                            />
                            <Icon
                                name="chevron down"
                                onClick={() => moveTopic(topic._id, "down")}
                            />
                            <Icon
                                name="trash"
                                onClick={() => setDeleteTopicConfirm(true)}
                            />
                        </Div>
                        {t(`builder.${cType}.topic`)} {idx + 1}:
                    </Div>
                )}
                <Div flex gapBottom>
                    <Div
                        grey
                        relative
                        wd="140px"
                        minHt="70px"
                        snubbed
                        noOverflow
                    >
                        <Image src={topic.image} />
                        <Div
                            absolute
                            layer={3}
                            style={{ bottom: "10px", right: "10px" }}
                        >
                            <Div flex clickable gapped>
                                <Button
                                    size="mini"
                                    icon="upload"
                                    secondary
                                    onClick={() => setImgEditorOpen(true)}
                                />
                            </Div>
                            <ImageUploader
                                modalOpen={imgEditorOpen}
                                handleModalClose={() => setImgEditorOpen(false)}
                                handleModalSave={(img) => {
                                    handleTopicUpdate("image", img, topic._id);
                                    setImgEditorOpen(false);
                                }}
                                value={topic.image}
                                aspectRatio={0.5}
                                mWidth={640}
                            />
                        </Div>
                    </Div>
                    <Div wd="calc(100% - 140px)" compact>
                        <Div bold>
                            <InlineInput
                                text={topic.name}
                                handleSave={(data) =>
                                    handleTopicUpdate("name", data, topic._id)
                                }
                            />
                        </Div>
                        <Div gapBottom>
                            <InlineTextarea
                                text={topic.description}
                                handleSave={(data) =>
                                    handleTopicUpdate(
                                        "description",
                                        data,
                                        topic._id
                                    )
                                }
                            />
                        </Div>
                    </Div>
                </Div>
                <Div gapBottom>
                    <Button
                        size="small"
                        disabled={
                            topic.contentList?.length >= user.license.maxDecks
                        }
                        primary
                        fluid
                        icon="plus"
                        labelPosition="left"
                        content={`${t(`builder.${cType}.addContent`)} (${
                            topic.contentList?.length
                        }/${user.license.maxDecks})`}
                        onClick={() => setNewModalOpen(true)}
                    />
                </Div>
                <Div ash medpad>
                    <Div bold compact gapBottom uppercase>
                        {t(`builder.${cType}.contentList`)}:
                    </Div>
                    <Div fluid ht="25rem" autoOverflowY>
                        {decksPanel(topic, idx)}
                    </Div>
                </Div>
                <CourseContentModal
                    {...{
                        newModalOpen,
                        setNewModalOpen,
                        courseId: course._id,
                        isExam: isExam,
                        topicId: topic._id,
                        sequence: topic.contentList.length,
                    }}
                />
            </Tab.Pane>
        );
    }

    const panes = _.concat(
        {
            menuItem: (
                <Menu.Item key="tree-header" className="content-tree-header">
                    {t(`builder.${cType}.topicListHeader`)}:
                </Menu.Item>
            ),
        },
        _.map(
            _.orderBy(course.locations, ["sequence"], ["asc"]),
            (topic, idx) => {
                return {
                    menuItem: (
                        <Menu.Item key={"folder-" + idx}>
                            <Icon name="chevron right" />
                            <Div wd="75%" noWrap>
                                <Icon
                                    name={
                                        idx === activeTab - 1
                                            ? "folder open"
                                            : "folder outline"
                                    }
                                    color={
                                        idx === activeTab - 1 ? "black" : "grey"
                                    }
                                />
                                {text_truncate(topic.name, 22)}
                            </Div>
                        </Menu.Item>
                    ),
                    render: () => topicPanel(topic, idx),
                };
            }
        ),
        {
            menuItem: (
                <Menu.Item key="tree-footer" className="content-tree-footer">
                    <Button
                        fluid
                        disabled={course?.locations.length >= 12}
                        primary
                        icon="folder"
                        labelPosition="left"
                        content={t(`builder.${cType}.addTopic`)}
                        onClick={addTopic}
                    />
                </Menu.Item>
            ),
        }
    );

    // ========================= Render Function =================================
    return (
        <Div fluid fullht flex noWrap spaceAround>
            <Div fullht wd="calc(100% - 320px)" basepad>
                <BuilderHeader
                    image={
                        course?.image
                            ? course?.image
                            : "/assets/images/configurable/missing.jpg"
                    }
                    header={`${t(`builder.${cType}.headers.content.header`)}: ${
                        course?.name
                    }`}
                    description={t(`builder.${cType}.headers.content.help`)}
                />
                {isExam ? (
                    <Div>{topicPanel(course.locations[0], 0, false)}</Div>
                ) : (
                    <Div
                        padTop
                        smoke
                        fluid
                        padSides
                        className="content-area"
                        ht={"calc(100% - 160px)"}
                        autoOverflowY
                    >
                        <Tab
                            menu={{ fluid: true, vertical: true }}
                            menuPosition="left"
                            activeIndex={activeTab}
                            panes={panes}
                            onTabChange={handleTabChange}
                        />
                    </Div>
                )}
            </Div>
            <Confirm
                open={deleteDeckConfirm}
                onCancel={() => setDeleteDeckConfirm(false)}
                onConfirm={doDeleteDeck}
            />
            <Confirm
                open={deleteTopicConfirm}
                onCancel={() => setDeleteTopicConfirm(false)}
                onConfirm={doDeleteTopic}
            />
            <PreviewLayout
                key={`course-preview-${course.wrapper}`}
                url={`courses/${course._id}`}
                message={
                    course.wrapper &&
                    WrapperList[course.wrapper] &&
                    WrapperList[course.wrapper].wrapperType !== "formal" &&
                    t(`builder.dic.previewHelp`)
                }
            />
        </Div>
    );
}
