import React, { useState, useEffect } from "react";
import { useGetBannerListQuery, useDeleteBannerMutation } from "@api/apiV6";
import _ from "lodash";
import ReactTable from "react-table-v6";
import Banner from "@components/Banner.react";

import { Div, Button } from "@components/Generics.react";
import { Loader, Icon, Confirm } from "semantic-ui-react";
import EditBannerModal from "./EditBannerModal.react";

export default function AdminBannerManagement(props) {
    const {
        data: bannersList,
        isSuccess,
        isLoading,
        refetch,
    } = useGetBannerListQuery();
    const [deleteBanner] = useDeleteBannerMutation();

    const [editModalOpen, setEditModalOpen] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [selectedBanner, setSelectedBanner] = useState(null);
    const [targetBanner, setTargetBanner] = useState(null);

    useEffect(() => {
        if (selectedBanner) {
            setSelectedBanner(
                _.find(bannersList, {
                    _id: selectedBanner._id,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannersList]);

    const downloadCSV = () => {
        const items =
            isSuccess &&
            _.map(bannersList, (a) => ({
                id: a._id,
                name: a.name,
                published: a.published,
                priority : a.priority
            }));
        if (items.length > 0) {
            const replacer = (key, value) => (value == null ? "" : value); 
            const header = Object.keys(items[0]);
            let csv = items.map((row) =>
                header
                    .map((fieldName) =>
                        JSON.stringify(row[fieldName], replacer)
                    )
                    .join(",")
            );
            csv.unshift(header.join(","));
            csv = csv.join("\r\n");
            var hiddenElement = document.createElement("a");
            hiddenElement.href =
                "data:text/csv;charset=utf-8," + encodeURI(csv);
            hiddenElement.target = "_blank";
            hiddenElement.download = `Bannerlist.csv`;
            hiddenElement.click();
        }
    };

    let fullColumns = [
        {
            Header: "Name",
            id: "name",
            accessor: "name",
            Cell: ({ row }) => {
                return (
                    <Div
                        txtMain
                        clickable
                        onClick={() =>
                            setSelectedBanner(
                                _.find(bannersList, {
                                    _id: row._original._id,
                                })
                            )
                        }
                    >
                        {row._original.name}
                    </Div>
                );
            },
        },
        {
            Header: "Published",
            id: "published",
            accessor: "published",
            Cell: (row) => (
                <div style={{ textAlign: "center" }}>
                    {row.value ? "Yes" : "No"}
                </div>
            ),
            width: 100,
        },
        {
            Header: "Priority",
            id: "priority",
            accessor: "priority",
            Cell: (row) => (
                <div style={{ textAlign: "center" }}>{row.value}</div>
            ),
            width: 100,
        },
        {
            Header: "",
            id: "actions",
            accessor: "_id",
            Cell: ({ row }) => {
                return (
                    <Div
                        txtDanger
                        clickable
                        onClick={() => {
                            setTargetBanner(row._original._id);
                            setConfirmDelete(true);
                        }}
                    >
                        <Icon name="trash" />
                    </Div>
                );
            },
            width: 50,
        },
    ];

    // ========================= Render Function =================================
    if (isLoading || !isSuccess) return <Loader active />;
    else
        return (
            <Div fullht flex>
                <Div wd="calc(100% - 360px)" fullht>
                    <Div white medpad ht="calc(100% - 123px)">
                        <Div fluid ash rimmed flex spaceBetween>
                            <Button
                                size="small"
                                secondary
                                icon="refresh"
                                labelPosition="left"
                                content={"Refresh Banners List"}
                                onClick={refetch}
                            />
                            <Div>
                                <Button
                                    size="small"
                                    primary
                                    icon="plus"
                                    labelPosition="left"
                                    content={"Create New Banner"}
                                    onClick={() => {
                                        setSelectedBanner(null);
                                        setEditModalOpen(true);
                                    }}
                                />
                                 <Button
                                    size="small"
                                    secondary
                                    icon="download"
                                    labelPosition="left"
                                    content="Download"
                                    onClick={downloadCSV}
                                />
                            </Div>
                        </Div>
                        <ReactTable
                            data={bannersList}
                            filterable
                            defaultFilterMethod={(filter, row, column) => {
                                const id = filter.pivotId || filter.id;
                                return row[id] !== undefined
                                    ? String(row[id])
                                          .toLowerCase()
                                          .includes(filter.value.toLowerCase())
                                    : true;
                            }}
                            columns={fullColumns}
                            pageSizeOptions={[5, 8]}
                            defaultPageSize={8}
                            className="-striped -highlight"
                        />
                    </Div>
                </Div>
                <Div wd="360px" fullht smoke basepad>
                    {selectedBanner ? (
                        <Div big>
                            <Div uppercase bold gutter>
                                {selectedBanner.name}
                            </Div>
                            <Banner ads={[selectedBanner]} />
                            <br />
                            <Button
                                primary
                                fluid
                                content="Edit Banner"
                                onClick={() => setEditModalOpen(true)}
                            />
                        </Div>
                    ) : (
                        <Div flex fullht fluid>
                            Select a banner or create new
                        </Div>
                    )}
                </Div>
                <EditBannerModal
                    isOpen={editModalOpen}
                    setOpen={setEditModalOpen}
                    banner={selectedBanner}
                />
                <Confirm
                    open={confirmDelete}
                    onCancel={() => setConfirmDelete(false)}
                    onConfirm={() => {
                        deleteBanner(targetBanner);
                        setConfirmDelete(false);
                    }}
                />
            </Div>
        );
}
