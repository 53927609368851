import React, { useState } from "react";
import {
    useGetArticleInventoryQuery,
    useDeleteArticleMutation,
} from "@api/apiV6";
import _ from "lodash";
import ReactTable from "react-table-v6";

import { Div, Button } from "@components/Generics.react";
import { Loader, Icon, Confirm } from "semantic-ui-react";
import UpsertArticleModal from "./UpsertArticleModal.react";
import { deckFormats } from "@schemas/deckFormats";

export default function AdminArticleManagement(props) {
    const {
        data: entityList,
        isSuccess,
        isLoading,
        refetch,
    } = useGetArticleInventoryQuery();
    const [deleteArticle] = useDeleteArticleMutation();

    const [newModalOpen, setNewModalOpen] = useState(false);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [targetArticle, setTargetArticle] = useState(null);

    const downloadCSV = () => {
        const items =
            isSuccess &&
            _.map(entityList, (a) => ({
                id: a.id,
                name: a.name,
                category: a.category,
                owner: a.owner?.username,
                identifier: a.identifier,
                tags: a.tags.map(tag => tag.name).join(", ")
            }));
        if (items.length > 0) {
            const replacer = (key, value) => (value == null ? "" : value); // specify how you want to handle null values here
            const header = Object.keys(items[0]);
            let csv = items.map((row) =>
                header
                    .map((fieldName) =>
                        JSON.stringify(row[fieldName], replacer)
                    )
                    .join(",")
            );
            csv.unshift(header.join(","));
            csv = csv.join("\r\n");
            var hiddenElement = document.createElement("a");
            hiddenElement.href =
                "data:text/csv;charset=utf-8," + encodeURI(csv);
            hiddenElement.target = "_blank";
            hiddenElement.download = `ArticleList.csv`;
            hiddenElement.click();
        }
    };

    let fullColumns = [
        {
            Header: "Article Name",
            id: "name",
            accessor: "name",
            Cell: ({ row }) => {
                return (
                    <Div
                        txtMain
                        clickable
                        onClick={() => {
                            setSelectedArticle(row._original);
                            setNewModalOpen(true);
                        }}
                    >
                        {row._original.name}
                    </Div>
                );
            },
            filterMethod: (filter, row) =>
                _.includes(
                    row._original.name.toLowerCase(),
                    filter.value.toLowerCase()
                ),
            width: 500,
        },
        {
            Header: "Type",
            id: "type",
            accessor: "owner",
            Cell: ({ row }) => (
                <Div center-align>
                    {
                        _.find(deckFormats, {
                            category: row._original.category,
                        })?.name
                    }
                </Div>
            ),
            filterMethod: (filter, row) =>
                _.includes(
                    _.find(deckFormats, {
                        category: row._original.category,
                    })?.name.toLowerCase(),
                    filter.value.toLowerCase()
                ),
        },
        {
            Header: "Owner",
            id: "owner",
            accessor: "owner",
            Cell: ({ row }) => <Div center-align>{row.owner?.username}</Div>,
            filterMethod: (filter, row) =>
                _.includes(
                    row.owner?.username.toLowerCase(),
                    filter.value.toLowerCase()
                ),
        },
        {
            Header: "Team",
            id: "team",
            accessor: "owner",
            Cell: ({ row }) => <Div center-align>{row.owner?.team}</Div>,
            filterMethod: (filter, row) =>
                _.includes(
                    row.owner?.team.toLowerCase(),
                    filter.value.toLowerCase()
                ),
        },
        {
            Header: "Actions",
            id: "id",
            accessor: "id",
            Cell: ({ row }) => {
                return (
                    <Div center-align txtDanger clickable>
                        <Icon
                            name={"trash"}
                            onClick={() => {
                                setTargetArticle(row._original._id);
                                setConfirmDelete(true);
                            }}
                        />
                    </Div>
                );
            },
        },
    ];

    function closeModal() {
        setNewModalOpen(false);
        setSelectedArticle(null);
    }
    // ========================= Render Function =================================
    if (isLoading || !isSuccess) return <Loader active />;
    else
        return (
            <Div fullht>
                <Div fluid fullht>
                    <Div white medpad ht="calc(100% - 123px)">
                        <Div fluid ash rimmed flex spaceBetween>
                            <Button
                                size="small"
                                secondary
                                icon="refresh"
                                labelPosition="left"
                                content={"Refresh Articles List"}
                                onClick={refetch}
                            />
                            <Div>
                                <Button
                                    size="small"
                                    primary
                                    icon="plus"
                                    labelPosition="left"
                                    content={"Create New Article"}
                                    onClick={() => setNewModalOpen(true)}
                                />
                                <Button
                                    size="small"
                                    secondary
                                    icon="download"
                                    labelPosition="left"
                                    content="Download"
                                    onClick={downloadCSV}
                                />
                            </Div>
                        </Div>
                        <ReactTable
                            data={entityList}
                            filterable
                            defaultFilterMethod={(filter, row, column) => {
                                const id = filter.pivotId || filter.id;
                                return row[id] !== undefined
                                    ? String(row[id])
                                          .toLowerCase()
                                          .includes(filter.value.toLowerCase())
                                    : true;
                            }}
                            columns={fullColumns}
                            pageSizeOptions={[5, 8]}
                            defaultPageSize={8}
                            className="-striped -highlight"
                        />
                    </Div>
                </Div>
                <Confirm
                    open={confirmDelete}
                    onCancel={() => setConfirmDelete(false)}
                    onConfirm={() => {
                        deleteArticle(targetArticle);
                        setConfirmDelete(false);
                    }}
                />
                {newModalOpen && (
                    <UpsertArticleModal
                        key={
                            selectedArticle
                                ? selectedArticle._id
                                : "new-article"
                        }
                        isOpen={newModalOpen}
                        setOpen={closeModal}
                        article={selectedArticle}
                    />
                )}
            </Div>
        );
}
