// Import from NPM
// -------------------------------------
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFeatures } from "flagged";
import { baseUrl } from "@api/apiV6";
import _ from "lodash";
import { Modal } from "semantic-ui-react";

import { Div, Button } from "@components/Generics.react";
import "@styles/editor.scss";
import * as XLSX from "xlsx";

import SingleBtn from "@components/ribbon/SingleBtn.react";
import RowBtns from "@components/ribbon/RowBtns.react";
import OneTwoBtns from "@components/ribbon/OneTwoBtns.react";
import ContentMarketplace from "../ContentMarketplace.react";

export default function GeneratorMenu(props) {
    const { deck, updateDeck, config, rightPanel, setRightPanel } = props;
    const replaceInputRef = useRef(null);
    const addInputRef = useRef(null);
    const { t } = useTranslation("common");
    const features = useFeatures();
    const [marketOpen, setMarketOpen] = useState(false);

    const replaceQuestions = (e) => {
        const uploadedFile = e.target.files[0];
        processQFile(uploadedFile, "replace");
    };
    const addQuestions = (e) => {
        const uploadedFile = e.target.files[0];
        processQFile(uploadedFile, "add");
    };

    function processQFile(uploadedFile, mode) {
        if (uploadedFile) {
            const reader = new FileReader();
            try {
                reader.onload = (e) => {
                    var data = e.target.result;
                    // Determine the correct type parameter for XLSX.read
                    var readType =
                        uploadedFile.type === "text/csv" ? "string" : "binary";

                    var workbook;
                    if (readType === "string") {
                        // Directly use the read data for CSV
                        workbook = XLSX.read(data, { type: "binary" });
                    } else {
                        // Convert data to array string for XLSX if necessary
                        if (data instanceof ArrayBuffer)
                            data = new Uint8Array(data);
                        workbook = XLSX.read(data, {
                            type: "array",
                        });
                    }

                    var sheet_name_list = workbook.SheetNames;
                    let targetSheet =
                        sheet_name_list.indexOf("UploadMod") !== -1
                            ? "UploadMod"
                            : sheet_name_list[0];
                    let excelData = XLSX.utils.sheet_to_json(
                        workbook.Sheets[targetSheet]
                    );
                    let contentHash = _.filter(excelData, (row) => {
                        return (
                            [
                                "single_select",
                                "multiple_select",
                                "true_false",
                                "fill_in_the_blanks",
                            ].indexOf(row.qtype) !== -1
                        );
                    });
                    if (mode === "replace")
                        updateDeck({
                            content: _.map(contentHash, (row, idx) =>
                                buildQuestions(row, idx, mode)
                            ),
                        });
                    else
                        updateDeck({
                            content: _.concat(
                                deck.content,
                                _.map(contentHash, (row, idx) =>
                                    buildQuestions(row, idx, mode)
                                )
                            ),
                        });
                };

                // Use readAsBinaryString for xlsx and readAsText for csv
                if (uploadedFile.type === "text/csv") {
                    reader.readAsBinaryString(uploadedFile);
                } else {
                    reader.readAsArrayBuffer(uploadedFile);
                }
            } catch (error) {
                console.error("Error parsing file", error);
            }
        }
    }

    function collateOptions(obj) {
        let collatedValues = [];

        for (const key in obj) {
            if (obj.hasOwnProperty(key) && key.startsWith("text")) {
                collatedValues.push(obj[key]);
            }
        }

        return collatedValues;
    }

    function buildQuestions(row, rowNo, mode) {
        const optKey =
            row.qtype === "true_false"
                ? "choice"
                : row.qtype === "multiple_select"
                ? "multiselect"
                : row.qtype === "single_select"
                ? "options"
                : null;
        return {
            template: row.qtype,
            slideSeq: mode === "replace" ? rowNo : rowNo + deck.content.length,
            nav: {
                next: true,
                prev: false,
            },
            data: {
                title: row.header,
                question: row.question,
                feedback: [row.correct_feedback, row.incorrect_feedback].filter(
                    Boolean
                ),
                purpose: "Test Understanding",
                [optKey]: _.map(collateOptions(row), (opt, idx) => ({
                    text: opt,
                    correct:
                        typeof row.correct === "string"
                            ? _.map(
                                  row.correct.split(","),
                                  (elm) => parseInt(elm, 0) - 1
                              ).indexOf(idx) !== -1
                                ? "checked"
                                : ""
                            : idx === row.correct - 1
                            ? "checked"
                            : "",
                })),
                answer: row.text,
            },
        };
    }

    function handleDownload() {
        const anchor = document.createElement("a");
        anchor.href = `${baseUrl}public/samples/${
            deck.readerType === "assessment" ? "quiz" : deck.readerType
        }_upload_format.xlsx`;
        anchor.download = `${
            deck.readerType === "assessment" ? "quiz" : deck.readerType
        }_upload_format.xlsx`;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }

    // ========================= Render Function =================================
    return (
        <Div flex flexStart gapped>
            {config.includes("qbank") && features.contentMarketplace && (
                <SingleBtn
                    name={t("builder.ribbon.questionBank")}
                    icon="shop"
                    action={() => setMarketOpen(true)}
                    leftBordered={false}
                />
            )}
            {config.includes("excel") && (
                <OneTwoBtns
                    name={t("builder.ribbon.excelUpload")}
                    btns={[
                        {
                            name: t("builder.ribbon.qsample"),
                            icon: "cloud download",
                            action: handleDownload,
                        },
                        {
                            name: t("builder.ribbon.qreplace"),
                            icon: "recycle",
                            action: () => replaceInputRef.current.click(),
                        },
                        {
                            name: t("builder.ribbon.qadd"),
                            icon: "add circle",
                            action: () => addInputRef.current.click(),
                        },
                    ]}
                />
            )}
            {config.includes("ai") && features.ability.builder.aiAssistant && (
                <RowBtns
                    name={t("builder.ribbon.ai")}
                    btns={[
                        {
                            name: t("builder.ribbon.aiTopic"),
                            icon: "text-color",
                            active: rightPanel === "aiqtopic",
                            action: () => setRightPanel("aiqtopic"),
                        },
                        {
                            name: t("builder.ribbon.aiSource"),
                            icon: "stack-text",
                            active: rightPanel === "aiqgen",
                            action: () => setRightPanel("aiqgen"),
                        },
                        {
                            name: t("builder.ribbon.aiTrans"),
                            icon: "language",
                            active: rightPanel === "aiqtrans",
                            action: () => setRightPanel("aiqtrans"),
                        },
                    ]}
                />
            )}
            {config.includes("ais") && features.ability.builder.aiAssistant && (
                <RowBtns
                    name={t("builder.ribbon.ai")}
                    btns={[
                        {
                            name: t("builder.ribbon.aiTopic"),
                            icon: "text-color",
                            active: rightPanel === "aistopic",
                            action: () => setRightPanel("aistopic"),
                        },
                        {
                            name: t("builder.ribbon.aiSource"),
                            icon: "stack-text",
                            active: rightPanel === "aisgen",
                            action: () => setRightPanel("aisgen"),
                        },
                        {
                            name: t("builder.ribbon.aiTrans"),
                            icon: "language",
                            active: rightPanel === "aistrans",
                            action: () => setRightPanel("aistrans"),
                        },
                    ]}
                />
            )}
            <Div hidden>
                <input
                    ref={replaceInputRef}
                    type="file"
                    accept=".xlsx, .csv"
                    onChange={replaceQuestions}
                />
            </Div>
            <Div hidden>
                <input
                    ref={addInputRef}
                    type="file"
                    accept=".xlsx, .csv"
                    onChange={addQuestions}
                />
            </Div>
            {features.contentMarketplace && (
                <Modal
                    size="fullscreen"
                    open={marketOpen}
                    onClose={() => setMarketOpen(false)}
                >
                    <Modal.Header>Question Bank Marketplace</Modal.Header>
                    <Modal.Content>
                        <ContentMarketplace processQFile={processQFile} />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            content="Close"
                            onClick={() => setMarketOpen(false)}
                        />
                    </Modal.Actions>
                </Modal>
            )}
        </Div>
    );
}
