import "@styles/layouts.scss";
import { useSelector } from "react-redux";
import { Div, Image } from "@components/Generics.react";
import { lightStyle } from "@utilities/helpers";
import { settings } from "@config/settings/app.settings";

export default function PreviewLayout(props) {
    const { url = "" } = props;
    // const { url = "", message } = props;
    const user = useSelector((state) => state.auth.user);

    // ========================= Render Function =================================
    return (
        <Div
            minWd="400px"
            wd="400px"
            fullht
            maxHt="calc(100vh - 75px)"
            relative
            padSides
            noOverflow
        >
            <Div absolute fluid fullht padRight noOverflow>
                <Image fluid src="/assets/images/mobile.png" />
            </Div>
            <Div
                absolute
                style={{
                    ...lightStyle(user.branding?.colors?.primary),
                    top: "44px",
                    left: "34px",
                    width: "334px",
                    height: "calc(100vh - 120px)",
                    maxHeight: "668px",
                    borderRadius: "10px",
                }}
                noOverflow
            >
                <iframe
                    key={Date.now()}
                    className="iframe-container"
                    title="Preview Wrapper"
                    src={`${settings.targetRoute}${url}?preview=true`}
                    width={"265px"}
                    height={"525px"}
                    style={{ border: 0 }}
                />
                {/* {message && (
                    <Div fluid style={{ marginTop: "-65px" }}>
                        <Div padded peat wd="80%" center-align centered>
                            {message}
                        </Div>
                    </Div>
                )} */}
            </Div>
        </Div>
    );
}
