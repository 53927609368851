import React from "react";
import { useTranslation } from "react-i18next";

import { Modal, Input, TextArea, Icon } from "semantic-ui-react";
import { Div, Button } from "@components/Generics.react";

export default function NotificationModal(props) {
    const { open, onClose, onConfirm, messageTitle, messageContent, onChangeTitle, onChangeContent } = props;
    const { t } = useTranslation("common");

    return (
        <Modal size="tiny" open={open} onClose={onClose}>
            <Modal.Header>
                {t(`builder.course.broadcastMsg.header`)}
                <Div float-right clickable>
                    <Icon
                        name="times"
                        onClick={onClose}
                    />
                </Div>
            </Modal.Header>
            <Modal.Content>
                <Div fluid>
                    <Div gutter>
                        {t(`builder.course.broadcastMsg.title1`)}
                    </Div>
                    <Div bold big gutter>
                        {t(`builder.course.broadcastMsg.subLine`)}
                    </Div>
                    <Div gutter>
                        <Input
                            fluid
                            value={messageTitle}
                            placeholder={t(`builder.course.broadcastMsg.msgTitle`)}
                            onChange={(e) => onChangeTitle(e.target.value)}
                        />
                    </Div>
                    <Div bold big gutter>
                        {t(`builder.course.broadcastMsg.customMsg`)}
                    </Div>
                    <Div trench>
                        <TextArea
                            value={messageContent}
                            rows={5}
                            placeholder={t(`builder.course.broadcastMsg.msgContent`)}
                            onChange={(e) => onChangeContent(e.target.value)}
                            style={{
                                width: "100%",
                                padding: "10px",
                                fontSize: "13px",
                                color: "rgba(0,0,0,0.87)",
                                border: "1px solid #ccc",
                            }}
                        />
                    </Div>
                    <Button
                        content={t(`components.save`)}
                        labelPosition="left"
                        primary
                        icon="save"
                        onClick={() => onConfirm(messageTitle, messageContent)}
                    />
                </Div>
            </Modal.Content>
        </Modal>
    );
}
