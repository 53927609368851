import React, { useState } from "react";
import { useGetTagsQuery, useDeleteTagMutation } from "@api/apiV6";
import _ from "lodash";
import ReactTable from "react-table-v6";
 
import { Div, Button } from "@components/Generics.react";
import { Loader, Icon, Confirm } from "semantic-ui-react";
import UpsertTagModal from "./UpsertTagModal.react";
 
export default function AdminTagManagement(props) {
    const { data: tagsList, isSuccess, isLoading, refetch } = useGetTagsQuery();
    const [deleteTag] = useDeleteTagMutation();
 
    const [newModalOpen, setNewModalOpen] = useState(false);
    const [selectedTag, setSelectedTag] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [targetTag, setTargetTag] = useState(null);
 
    const downloadCSV = () => {
        const items =
            isSuccess &&
            _.map(tagsList, (a) => ({
                id: a._id,
                name: a.name
            }));
        if (items.length > 0) {
            const replacer = (key, value) => (value == null ? "" : value); // specify how you want to handle null values here
            const header = Object.keys(items[0]);
            let csv = items.map((row) =>
                header
                    .map((fieldName) =>
                        JSON.stringify(row[fieldName], replacer)
                    )
                    .join(",")
            );
            csv.unshift(header.join(","));
            csv = csv.join("\r\n");
            var hiddenElement = document.createElement("a");
            hiddenElement.href =
                "data:text/csv;charset=utf-8," + encodeURI(csv);
            hiddenElement.target = "_blank";
            hiddenElement.download = `Taglist.csv`;
            hiddenElement.click();
        }
    };
 
    let fullColumns = [
        {
            Header: "Tag Name",
            id: "name",
            accessor: "name",
            Cell: ({ row }) => {
                return (
                    <Div
                        txtMain
                        clickable
                        onClick={() => {
                            setSelectedTag(row._original);
                            setNewModalOpen(true);
                        }}
                    >
                        {row._original.name}
                    </Div>
                );
            },
        },
        {
            Header: "Actions",
            id: "id",
            accessor: "id",
            Cell: ({ row }) => {
                return (
                    <Div center-align txtDanger clickable>
                        <Icon
                            name="trash"
                            onClick={() => {
                                setTargetTag(row._original._id);
                                setConfirmDelete(true);
                            }}
                        />
                    </Div>
                );
            },
        },
    ];
 
    function closeModal() {
        setNewModalOpen(false);
        setSelectedTag(null);
    }
 
    // ========================= Render Function =================================
    if (isLoading || !isSuccess) return <Loader active />;
    else
        return (
            <Div fullht>
                <Div fluid fullht>
                    <Div white medpad ht="calc(100% - 123px)">
                        <Div fluid ash rimmed flex spaceBetween>
                            <Button
                                size="small"
                                secondary
                                icon="refresh"
                                labelPosition="left"
                                content={"Refresh Tags List"}
                                onClick={refetch}
                            />
                            <Div>
                                <Button
                                    size="small"
                                    primary
                                    icon="plus"
                                    labelPosition="left"
                                    content={"Create New Tag"}
                                    onClick={() => setNewModalOpen(true)}
                                />
                                <Button
                                    size="small"
                                    secondary
                                    icon="download"
                                    labelPosition="left"
                                    content="Download"
                                    onClick={downloadCSV}
                                />
                            </Div>
                        </Div>
                        <ReactTable
                            data={tagsList}
                            filterable
                            defaultFilterMethod={(filter, row, column) => {
                                const id = filter.pivotId || filter.id;
                                return row[id] !== undefined
                                    ? String(row[id])
                                          .toLowerCase()
                                          .includes(filter.value.toLowerCase())
                                    : true;
                            }}
                            columns={fullColumns}
                            pageSizeOptions={[5, 8]}
                            defaultPageSize={8}
                            className="-striped -highlight"
                        />
                    </Div>
                </Div>
                <Confirm
                    open={confirmDelete}
                    onCancel={() => setConfirmDelete(false)}
                    onConfirm={() => {
                        deleteTag(targetTag);
                        setConfirmDelete(false);
                    }}
                />
                <UpsertTagModal
                    key={selectedTag ? selectedTag._id : "new-tag"}
                    isOpen={newModalOpen}
                    setOpen={closeModal}
                    tag={selectedTag}
                />
            </Div>
        );
}
 