import React, { useState } from "react";
import _ from "lodash";
import { Modal, Form, Header, List, Segment, Grid, Icon, Progress } from "semantic-ui-react";
import * as XLSX from "xlsx";
import FileSaver from 'file-saver';
import { settings } from "@config/settings/app.settings";
import { Div, Button } from "@components/Generics.react";
import { useEditUserMutation, useGetAllUsersQuery } from "@api/apiV6";
import { useTranslation } from "react-i18next";
export default function UploadBulkModifyUsers() {
    const [loading, setLoading] = useState(false);
    const [xlsFile, setXlsFile] = useState(null);
    const [processingStatus, setProcessingStatus] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [progress, setProgress] = useState(0);
    const [editUser] = useEditUserMutation();
    const [estimationTime, setEstimationTime] = useState(null);
    const [successfulCount, setSuccessfulCount] = useState(0);
    const [unsuccessfulCount, setUnsuccessfulCount] = useState(0);
    const handleXLSFileLoad = e => setXlsFile(e.target.files[0]);
    const { t } = useTranslation("common");
    const { data: audienceData } = useGetAllUsersQuery();

    const processFile = async () => {
        try {
            const detailParamKeys = _.keys(settings.detailParams);
            setLoading(true);
            const rABS = true;
            const f = xlsFile;
            const reader = new FileReader();
            const startTime = Date.now(); // Get the start time

            reader.onload = async function (e) {
                var data = e.target.result;
                if (!rABS) data = new Uint8Array(data);
                var workbook = XLSX.read(data, { type: rABS ? "binary" : "array" });
                const excelData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
                const sheetHeaders = Object.keys(excelData[0]);
                setHeaders(sheetHeaders);

                for (let i = 0; i < excelData.length; i++) {
                    const row = excelData[i];
                    const rowDetails = {};
                    _.each(detailParamKeys, (detail) => {
                        rowDetails[detail] = row[detail];
                    });
                    const usernamesFromExcel = [row.username.toString()];

                    const filterMatchedUsers = (usernamesFromExcel, audienceData) => {
                        // Check either usernamesFromExcel or audienceData is empty or not an array");
                        if (!Array.isArray(usernamesFromExcel) || !audienceData) {
                            return [];
                        }

                        // Find Matched User Which Available on DB and Excel sheet
                        const audienceUsernames = new Set(audienceData.map(user => user.username)); // Convert to Set for faster lookup

                        const matchedUsers = usernamesFromExcel
                            .filter(username => audienceUsernames.has(username))
                            .map(username => {
                                const matchedUserData = audienceData.find(user => user.username === username);
                                return { id: matchedUserData._id, username: matchedUserData.username };
                            });
                        return matchedUsers;
                    };

                    const matchedUserObject = filterMatchedUsers(usernamesFromExcel, audienceData);

                    const id = matchedUserObject.map(user => user.id);
                      
                    let trainers = [];
                    const trainerData = row.trainers ? typeof row.trainers === 'string' ? row.trainers.trim() : row.trainers.toString().trim() : "";
                    trainers = trainerData.indexOf(',') !== -1 ? trainerData.split(',') : [trainerData];

                    try {
                        const isValidUser = audienceData.some(user => user.username.toString() === row.username.toString());     

                        if (isValidUser) {
                            const response = await editUser({
                                id: id,
                                username: row.username.toString(),
                                manager: row.manager.toString(),
                                details: rowDetails,
                                active: row.active,
                                stage : row.stage,
                                team : row.team,
                                trainer: trainers
                            });
                            if (response) {
                                //  If Successfully updated user 
                                setProcessingStatus(prevStatus => [...prevStatus, { row, status: true, result: "Updated" }]);
                                setSuccessfulCount(prevCount => prevCount + 1);
                            } else {
                                // If row.username is not valid. Failed to update
                                setProcessingStatus(prevStatus => [...prevStatus, { row, status: false, result: response.status }]);
                                setUnsuccessfulCount(prevCount => prevCount + 1);
                            }
                        } else {
                            // If row.username is not valid. Failed to update
                            setProcessingStatus(prevStatus => [...prevStatus, { row, status: false, result: "User is not valid" }]);
                            setUnsuccessfulCount(prevCount => prevCount + 1);
                        }
                    } catch (err) {
                        console.error("API call failed:", err);
                    }

                    const newProgress = Math.round(((i + 1) / excelData.length) * 100);
                    setProgress(newProgress);

                    // Calculate estimation time
                    const elapsedTime = Date.now() - startTime;
                    const expectedTime = (elapsedTime / (i + 1)) * (excelData.length - i - 1);
                    const minutes = Math.floor(expectedTime / 60000);
                    const seconds = ((expectedTime % 60000) / 1000).toFixed(0);
                    setEstimationTime(`${minutes} minutes ${seconds} seconds`);
                }
                setLoading(false);
                setXlsFile(null);
            };

            if (rABS) reader.readAsBinaryString(f);
            else reader.readAsArrayBuffer(f);
        } catch (error) {
            console.error("Error processing file:", error);
            setLoading(false);
        }
    };

    const downloadCSV = (fileName, content) => {
        const file = new File([content], fileName, { type: "text/csv;charset=utf-8" });
        FileSaver.saveAs(file);
    };

    const downloadSuccess = () => {
        const processed = _.filter(processingStatus, row => row.status);
        let csvContent = _.map(_.toPairs(headers), pair => pair[1]).join(",") + "\r\n";

        _.each(processed, row => {
            const thisRow = _.map(_.toPairs(row.row), pair => pair[1]).join(",");
            csvContent += thisRow + "\r\n";
        });

        downloadCSV("successful.csv", csvContent);
    };

    const downloadFailure = () => {
        const unprocessed = _.filter(processingStatus, row => !row.status);
        let csvContent = _.map(_.toPairs(headers), pair => pair[1]).join(",") + "\r\n";

        _.each(unprocessed, row => {
            const rowData = row.row;
            let rowContent = "";
            _.each(headers, header => {
                if (rowData.hasOwnProperty(header)) {
                    rowContent += `"${rowData[header]}"`;
                }
                rowContent += ",";
            });
            rowContent += row.result; // Add the result of the failed row
            csvContent += rowContent + "\r\n";
        });

        downloadCSV("unsuccessful.csv", csvContent);
    };

    return (
        <Div>
            <Header icon="cloud upload" loading content="Upload Bulk Users" />
            <Form>
                <Modal.Content>
                    <Segment basic style={{ background: "#f5f5f5" }}>
                        <Segment.Group horizontal>

                            {!loading && processingStatus.length === 0 && (
                                <Segment raised>
                                    <Div gutter bold big>
                                        Step 1: Download Sample File
                                    </Div>
                                    <Button
                                        fluid
                                        primary
                                        content="Sample File"
                                        href={
                                            settings.baseUrl.endsWith("/")
                                                ? settings.baseUrl +
                                                "public/samples/modify_user_upload_format.xlsx"
                                                : settings.baseUrl +
                                                "/public/samples/modify_user_upload_format.xlsx"
                                        }
                                    />
                                </Segment>
                            )}
                            <Segment raised>
                                {!loading && processingStatus.length === 0 && (
                                    <Div>
                                        <Div gutter bold big>
                                            Step 2: Upload Excel File with Users
                                        </Div>
                                        <input
                                            type="file"
                                            onChange={handleXLSFileLoad}
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        />
                                    </Div>
                                )}
                                {processingStatus.length !== 0 && (
                                    <Segment basic style={{ background: "#f5f5f5" }}>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <Progress percent={progress} indicating>
                                                        {progress}%
                                                        {estimationTime && ` | Estimation Time: ${estimationTime}`}
                                                    </Progress>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={8}>
                                                    <h3>
                                                        <Icon
                                                            name="download"
                                                            circular
                                                            inverted
                                                            color="yellow"
                                                            onClick={downloadSuccess}
                                                            size="small"
                                                            style={{ cursor: "pointer" }}
                                                        />{" "}
                                                        Successfully Processed: {successfulCount}
                                                    </h3>
                                                    <Div ht="17em" autoOverflowY >
                                                        <List
                                                            divided
                                                            style={{
                                                                width: "100%",
                                                                fontSize: "1.2em",
                                                            }}
                                                        >
                                                            {_.map(processingStatus, (row, idx) => {
                                                                if (row.status) {
                                                                    return (
                                                                        <List.Item key={`upload-item-${idx}`}>
                                                                            {row.row.username}: {row.result}
                                                                        </List.Item>
                                                                    );
                                                                }
                                                            })}
                                                        </List>
                                                    </Div>
                                                    <Div padded>
                                                        <Button
                                                            primary
                                                            content="Download Success"
                                                            onClick={downloadSuccess}
                                                        />
                                                    </Div>
                                                </Grid.Column>
                                                <Grid.Column width={8}>
                                                    <h3>
                                                        <Icon
                                                            name="download"
                                                            circular
                                                            inverted
                                                            color="yellow"
                                                            onClick={downloadFailure}
                                                            size="small"
                                                            style={{ cursor: "pointer" }}
                                                        />{" "}
                                                        Unsuccessful Upload: {unsuccessfulCount}
                                                    </h3>
                                                    <Div ht="17em" autoOverflowY >
                                                        <List
                                                            divided
                                                            style={{
                                                                width: "100%",
                                                                fontSize: "1.2em",
                                                            }}
                                                        >
                                                            {_.map(processingStatus, (row, idx) => {
                                                                if (!row.status) {
                                                                    return (
                                                                        <List.Item key={`not-upload-item-${idx}`}>
                                                                            {row.row.username === ""
                                                                                ? "Unknown Username"
                                                                                : row.row.username}: {row.result}
                                                                        </List.Item>
                                                                    );
                                                                }
                                                            })}
                                                        </List>
                                                    </Div>

                                                    <Div padded >
                                                        <Button
                                                            primary
                                                            content="Download Failure"
                                                            onClick={downloadFailure}
                                                        />
                                                    </Div>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Segment>
                                )}
                            </Segment>

                        </Segment.Group>
                        <Div basepad vapor gutter>
                            <Div bold>
                                {t("auth.registration.bulkGuidelines")}
                            </Div>
                            <ul>
                                <li>
                                    <b>
                                        {t("auth.registration.usernameLabel")}:
                                    </b>{" "}
                                    {t("admin.bulkUpload.usernameHelp")}
                                </li>
                            </ul>
                        </Div>
                    </Segment>
                </Modal.Content>

                {!loading && processingStatus.length === 0 && (
                    <Button
                        secondary
                        size="large"
                        disabled={xlsFile === null}
                        content="Submit"
                        onClick={processFile}
                    />
                )}
            </Form>
        </Div>
    );
};


