import _ from "lodash";

import Title from "@player/decks/slideTemplates/Title.react";
import TitleSubtitle from "@player/decks/slideTemplates/TitleSubtitle.react";
import Paragraph from "@player/decks/slideTemplates/Paragraph.react";
import BackgroundImage from "@player/decks/slideTemplates/BackgroundImage.react";
import SingleSelect from "@player/decks/slideTemplates/SingleSelect.react";
import TrueFalse from "@player/decks/slideTemplates/TrueFalse.react";
import MultipleSelect from "@player/decks/slideTemplates/MultipleSelect.react";
import Splash from "@player/decks/slideTemplates/Splash.react";
import Brancher from "@player/decks/slideTemplates/Brancher.react";
import BulletList from "@player/decks/slideTemplates/BulletList.react";
import LineBulletList from "@player/decks/slideTemplates/LineBulletList.react";
import IconList from "@player/decks/slideTemplates/IconList.react";
import Blurb from "@player/decks/slideTemplates/Blurb.react";
import ExternalLink from "@player/decks/slideTemplates/ExternalLink.react";
import InteralLink from "@player/decks/slideTemplates/InternalLink.react";
import OpenInternalLink from "@player/decks/slideTemplates/OpenInternalLink.react";
import AnimatedBulletList from "@player/decks/slideTemplates/AnimatedBulletList.react";
import Conversation from "@player/decks/slideTemplates/Conversation.react";
import ParagraphLine from "@player/decks/slideTemplates/ParagraphLine.react";
import VideoFullParagraph from "@player/decks/slideTemplates/VideoFullParagraph.react";
import Video from "@player/decks/slideTemplates/Video.react";
import DoDontList from "@player/decks/slideTemplates/DoDontList.react";
import ImageCaption from "@player/decks/slideTemplates/ImageCaption.react";
import IconListGallery from "@player/decks/slideTemplates/IconListGallery.react";
import Diptych from "@player/decks/slideTemplates/Diptych.react";
import Triptych from "@player/decks/slideTemplates/Triptych.react";
import Grid from "@player/decks/slideTemplates/Grid.react";
import Cards from "@player/decks/slideTemplates/Cards.react";
import Gallery from "@player/decks/slideTemplates/Gallery.react";
import PopupList from "@player/decks/slideTemplates/PopupList.react";
import PDFViewer from "@player/decks/slideTemplates/PDFViewer.react";
import FITB from "@player/decks/slideTemplates/FITB.react";
import OpenAnswer from "@player/decks/slideTemplates/OpenAnswer.react";
import Anagram from "@player/decks/slideTemplates/Anagram.react";
import PictureFuddle from "@player/decks/slideTemplates/PictureFuddle.react";
import PickPicture from "@player/decks/slideTemplates/PickPicture.react";
import RankThemRight from "@player/decks/slideTemplates/RankThemRight.react";

import HTMLField from "../pages/creator/decks/editors/formComponents/HTMLField.react";
import InputField from "../pages/creator/decks/editors/formComponents/InputField.react";
import ButtonField from "../pages/creator/decks/editors/formComponents/ButtonField.react";
import ImageField from "../pages/creator/decks/editors/formComponents/ImageField.react";
import FeedbackField from "../pages/creator/decks/editors/formComponents/FeedbackField.react";
import RadioField from "../pages/creator/decks/editors/formComponents/RadioField.react";
import CheckField from "../pages/creator/decks/editors/formComponents/CheckField.react";
import RadioImgField from "../pages/creator/decks/editors/formComponents/RadioImgField.react";
import SelectField from "../pages/creator/decks/editors/formComponents/SelectField.react";
import FileField from "../pages/creator/decks/editors/formComponents/FileField.react";
import ZoomableImage from "../pages/player/decks/slideTemplates/ZoomableImage.react";

export const TemplateList = {
    title: {
        key: "title",
        name: "Title",
        template: Title,
        showHeader: false,
        showHeaderImg: true,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: false, interactive: false },
        components: ["title"],
        target: {
            qdf: true,
            assessment: false,
            survey: true,
            game: false,
        },
    },
    title_subtitle: {
        key: "title_subtitle",
        name: "Title Subtitle",
        template: TitleSubtitle,
        showHeader: false,
        showHeaderImg: true,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: false, interactive: false },
        components: ["title", "subtitle"],
        target: {
            qdf: true,
            assessment: false,
            survey: true,
            game: false,
        },
    },
    paragraph: {
        key: "paragraph",
        name: "Paragraph",
        template: Paragraph,
        showHeader: true,
        showHeaderImg: true,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["title", "subtitle", "paragraph"],
        target: {
            qdf: true,
            assessment: false,
            survey: true,
            game: false,
        },
    },
    background_image: {
        key: "background_image",
        name: "Background Image",
        template: BackgroundImage,
        showHeader: false,
        showHeaderImg: false,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["title", "background", "position", "paragraph"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    splash: {
        key: "splash",
        name: "Splash",
        template: Splash,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["splash"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    bulletlist: {
        key: "bulletlist",
        name: "Bullet List",
        template: BulletList,
        overlay: null,
        showHeader: true,
        showHeaderImg: true,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["image", "title", "subtitle", "bullets"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    iconlist: {
        key: "iconlist",
        name: "Icon List",
        template: IconList,
        overlay: null,
        showHeader: true,
        showHeaderImg: true,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["image", "title", "subtitle", "iconlist"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    iconlist_timeline: {
        key: "iconlist_timeline",
        name: "Icon List Timeline",
        template: IconList,
        overlay: null,
        showHeader: true,
        showHeaderImg: true,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["image", "title", "subtitle", "iconlist"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    iconlist_gallery: {
        key: "iconlist_gallery",
        name: "Icon List Gallery",
        template: IconListGallery,
        overlay: null,
        showHeader: true,
        showHeaderImg: true,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["image", "title", "subtitle", "cards"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    iconlist_captions: {
        key: "iconlist_captions",
        name: "Icon List Captioned Gallery",
        template: IconListGallery,
        overlay: null,
        showHeader: true,
        showHeaderImg: true,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["image", "title", "subtitle", "cards"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    brancher_template: {
        key: "brancher_template",
        name: "Brancher",
        template: Brancher,
        showHeader: true,
        showHeaderImg: true,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: false, interactive: true },
        components: ["image", "title", "paragraph", "brancher"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    line_bulletlist: {
        key: "line_bulletlist",
        name: "Captioned Bullet List",
        template: LineBulletList,
        showHeader: true,
        showHeaderImg: true,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["title", "subtitle", "paragraph", "bullets"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    blurb: {
        key: "blurb",
        name: "Blurb",
        template: Blurb,
        showHeader: true,
        showHeaderImg: true,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["image", "title", "subtitle", "paragraph"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    external_link: {
        key: "external_link",
        name: "External Link",
        template: ExternalLink,
        showHeader: true,
        showHeaderImg: true,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["image", "title", "subtitle", "paragraph", "link"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    internal_link: {
        key: "internal_link",
        name: "Link to Slide",
        template: InteralLink,
        overlay: null,
        questionable: false,
        scorable: false,
        showHeader: true,
        showHeaderImg: true,
        intracation: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: [
            "image",
            "title",
            "subtitle",
            "paragraph",
            "internal_link",
        ],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    open_internal_link: {
        key: "open_internal_link",
        name: "Internal Link",
        template: OpenInternalLink,
        overlay: null,
        questionable: false,
        scorable: false,
        showHeader: true,
        showHeaderImg: true,
        intracation: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: [
            "image",
            "title",
            "subtitle",
            "paragraph",
            "internal_link",
        ],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    animated_bulletlist: {
        key: "animated_bulletlist",
        name: "Carousel List",
        template: AnimatedBulletList,
        overlay: null,
        questionable: false,
        scorable: false,
        showHeader: true,
        showHeaderImg: false,
        interaction: "",
        audit: { verbose: true, visual: true, interactive: true },
        components: ["title", "subtitle", "bullets", "image"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    conversation: {
        key: "conversation",
        name: "Conversation",
        template: Conversation,
        overlay: null,
        questionable: false,
        showHeader: true,
        showHeaderImg: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: true, interactive: false },
        components: ["image", "title", "subtitle", "avatars", "conversation"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    video: {
        key: "video",
        name: "Video",
        template: Video,
        overlay: null,
        questionable: false,
        showHeader: true,
        showHeaderImg: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: true, interactive: false },
        components: ["title", "subtitle", "video"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    video_caption: {
        key: "video_caption",
        name: "Captioned Video",
        template: Video,
        overlay: null,
        questionable: false,
        showHeader: true,
        showHeaderImg: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: true, interactive: false },
        components: ["title", "subtitle", "video", "caption"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    video_full_title: {
        key: "video_full_title",
        name: "Videoed Title",
        template: VideoFullParagraph,
        overlay: null,
        questionable: false,
        showHeader: false,
        showHeaderImg: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: true, interactive: false },
        components: ["title", "video"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    video_full_paragraph: {
        key: "video_full_paragraph",
        name: "Video Background",
        template: VideoFullParagraph,
        overlay: null,
        questionable: false,
        showHeader: false,
        showHeaderImg: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: true, interactive: false },
        components: ["paragraph", "video"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    dodontlist: {
        key: "dodontlist",
        name: "Dos and Donts List",
        template: DoDontList,
        showHeader: true,
        showHeaderImg: true,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: true },
        components: ["title", "subtitle", "bullets", "rebullets"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    paragraph_line: {
        key: "paragraph_line",
        name: "Captioned Paragraph",
        template: ParagraphLine,
        showHeader: true,
        showHeaderImg: true,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["title", "subtitle", "paragraph", "caption"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    image: {
        key: "image",
        name: "Image",
        template: ImageCaption,
        overlay: "",
        showHeader: true,
        showHeaderImg: false,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["title", "subtitle", "image"],

        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    image_caption: {
        key: "image_caption",
        name: "Captioned Image",
        template: ImageCaption,
        overlay: "",
        showHeader: true,
        showHeaderImg: false,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["title", "subtitle", "image", "caption"],

        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    image_zoom: {
        key: "image_zoom",
        name: "Zoomable Image",
        template: ZoomableImage,
        overlay: "",
        showHeader: true,
        showHeaderImg: false,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["title", "subtitle", "image", "caption"],

        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    diptych: {
        key: "diptych",
        name: "Diptych",
        template: Diptych,
        overlay: "",
        showHeader: true,
        showHeaderImg: false,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["title", "subtitle", "diptych"],

        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    triptych: {
        key: "triptych",
        name: "Triptych",
        template: Triptych,
        overlay: "",
        showHeader: true,
        showHeaderImg: false,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["title", "subtitle", "triptych"],

        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    grid: {
        key: "grid",
        name: "Grid",
        template: Grid,
        overlay: "",
        showHeader: true,
        showHeaderImg: false,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["title", "subtitle", "grid"],

        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    cards: {
        key: "cards",
        name: "Cards",
        template: Cards,
        overlay: "",
        showHeader: true,
        showHeaderImg: false,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["title", "subtitle", "cards"],

        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    gallery: {
        key: "gallery",
        name: "Gallery",
        template: Gallery,
        overlay: "",
        showHeader: true,
        showHeaderImg: false,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["title", "subtitle", "cards"],

        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    popuplist: {
        key: "popuplist",
        name: "Popup List",
        template: PopupList,
        overlay: "",
        showHeader: true,
        showHeaderImg: true,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["title", "subtitle", "popupitems"],

        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    pdfviewer: {
        key: "pdfviewer",
        name: "PDF Viewer",
        template: PDFViewer,
        overlay: "",
        showHeader: true,
        showHeaderImg: false,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["title", "subtitle", "pdfviewer"],

        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    single_select: {
        key: "single_select",
        name: "Single Select",
        template: SingleSelect,
        showHeader: false,
        showHeaderImg: true,
        overlay: null,
        questionable: true,
        scorable: true,
        interaction: "singleSelect",
        audit: { verbose: false, visual: false, interactive: true },
        components: ["image", "question", "options", "feedback"],
        target: {
            qdf: true,
            assessment: true,
            survey: true,
            game: true,
        },
    },
    pick_picture: {
        key: "pick_picture",
        name: "Pick Picture",
        template: PickPicture,
        showHeader: false,
        showHeaderImg: true,
        overlay: null,
        questionable: true,
        scorable: true,
        interaction: "singleSelect",
        audit: { verbose: false, visual: false, interactive: true },
        components: ["image", "question", "imageOpts", "feedback"],
        target: {
            qdf: true,
            assessment: true,
            survey: true,
            game: true,
        },
    },
    true_false: {
        key: "true_false",
        name: "True or False",
        template: TrueFalse,
        showHeader: false,
        showHeaderImg: true,
        overlay: null,
        questionable: true,
        scorable: true,
        interaction: "singleSelect",
        audit: { verbose: false, visual: false, interactive: true },
        components: ["image", "question", "choice", "feedback"],
        target: {
            qdf: true,
            assessment: true,
            survey: true,
            game: true,
        },
    },
    odd_one_out: {
        key: "odd_one_out",
        name: "Odd One Out",
        template: SingleSelect,
        showHeader: false,
        showHeaderImg: true,
        overlay: null,
        questionable: true,
        scorable: true,
        interaction: "singleSelect",
        audit: { verbose: false, visual: false, interactive: true },
        components: ["image", "question", "options", "feedback"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    multiple_select: {
        key: "multiple_select",
        name: "Multiple Select",
        template: MultipleSelect,
        showHeader: false,
        showHeaderImg: true,
        overlay: null,
        questionable: true,
        scorable: true,
        interaction: "multipleSelect",
        audit: { verbose: false, visual: false, interactive: true },
        components: ["image", "question", "multiselect", "feedback"],
        target: {
            qdf: true,
            assessment: true,
            survey: true,
            game: true,
        },
    },
    fill_in_the_blanks: {
        key: "fill_in_the_blanks",
        name: "Fill In The Blanks",
        template: FITB,
        showHeader: false,
        showHeaderImg: true,
        overlay: null,
        questionable: true,
        scorable: true,
        interaction: "fitb",
        audit: { verbose: false, visual: false, interactive: true },
        components: ["image", "question", "answer", "feedback"],
        target: {
            qdf: true,
            assessment: true,
            survey: true,
            game: false,
        },
    },
    detailer: {
        key: "detailer",
        name: "Detailer",
        template: FITB,
        showHeader: false,
        showHeaderImg: true,
        overlay: null,
        questionable: true,
        scorable: true,
        interaction: "fitb",
        audit: { verbose: false, visual: false, interactive: true },
        components: ["question", "answer", "feedback"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    anagram: {
        key: "anagram",
        name: "Anagram",
        template: Anagram,
        showHeader: false,
        showHeaderImg: true,
        overlay: null,
        questionable: true,
        scorable: true,
        interaction: "fitb",
        audit: { verbose: false, visual: false, interactive: true },
        components: ["question", "answer", "feedback"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    open_answer: {
        key: "open_answer",
        name: "Open Answer",
        template: OpenAnswer,
        showHeader: false,
        showHeaderImg: true,
        overlay: null,
        questionable: true,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: false, interactive: true },
        components: ["image", "question"],
        target: {
            qdf: true,
            assessment: false,
            survey: true,
            game: false,
        },
    },
    picture_fuddle: {
        key: "picture_fuddle",
        name: "Picture Fuddle",
        template: PictureFuddle,
        showHeader: false,
        showHeaderImg: false,
        overlay: null,
        questionable: true,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: false, interactive: true },
        components: ["question", "image", "answer", "feedback"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    feedback_paragraph: {
        key: "feedback_paragraph",
        name: "Feedback Paragraph",
        template: PictureFuddle,
        showHeader: false,
        showHeaderImg: false,
        overlay: null,
        questionable: true,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: false, interactive: true },
        components: ["question", "image", "answer", "feedback"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    rank_them_right: {
        key: "rank_them_right",
        name: "Rank Them Right",
        template: RankThemRight,
        showHeader: false,
        showHeaderImg: true,
        overlay: null,
        questionable: true,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: false, interactive: true },
        components: ["image", "question", "bullets", "feedback"],
        target: {
            qdf: true,
            assessment: true,
            survey: false,
            game: false,
        },
    },
    timeliner: {
        key: "timeliner",
        name: "Timeliner",
        template: RankThemRight,
        showHeader: false,
        showHeaderImg: true,
        overlay: null,
        questionable: true,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: false, interactive: true },
        components: ["question", "bullets", "feedback"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    icon_caption: {
        key: "icon_caption",
        name: "Captioned Icon",
        template: ImageCaption,
        overlay: "",
        showHeader: true,
        showHeaderImg: false,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["title", "subtitle", "image", "caption"],

        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
};

export const ComponentMap = {
    banner: {
        target: "image",
        formField: ImageField,
        header: "Banner",
        aspectRatio: 0.5,
    },
    title: {
        target: "title",
        formField: InputField,
        header: "Title",
        maxLength: 50,
    },
    subtitle: {
        target: "subtitle",
        formField: InputField,
        header: "Subtitle",
        maxLength: 80,
    },
    caption: {
        target: "caption",
        formField: InputField,
        header: "Caption",
        maxLength: 120,
    },
    paragraph: {
        target: "paragraph",
        formField: HTMLField,
        header: "Paragraph",
        maxLength: 250,
    },
    splash: {
        target: "splash",
        formField: InputField,
        header: "Paragraph",
        maxLength: 80,
    },
    image: {
        target: "image",
        formField: ImageField,
        header: "Image",
        aspectRatio: 0.5625,
    },
    avatars: {
        target: "avatars",
        formField: [ImageField],
        header: "Avatar",
        aspectRatio: 1,
    },
    pdfviewer: {
        target: "pdf",
        formField: FileField,
        header: "File",
        accepts: ".pdf",
        maximum: 2048,
    },
    background: {
        target: "background",
        formField: ImageField,
        header: "Background",
        aspectRatio: 1.77,
    },
    popupitems: {
        target: "popupitems",
        subheader: "option",
        flatField: true,
        formField: [
            {
                target: "image",
                formField: ImageField,
                header: "Card Image",
            },
            {
                target: "option",
                formField: InputField,
                header: "Option",
                maxLength: 80,
            },
            {
                target: "caption",
                formField: InputField,
                header: "Caption",
                maxLength: 80,
            },
        ],
        header: "PopupList",
        aspectRatio: 0.5625,
    },
    diptych: {
        target: "diptych",
        subheader: "caption",
        flatField: true,
        formField: [
            {
                target: "image",
                formField: ImageField,
                header: "Card Image",
            },
            {
                target: "caption",
                formField: InputField,
                header: "Caption",
                maxLength: 80,
            },
        ],
        header: "Diptych",
        aspectRatio: 0.5625,
    },
    triptych: {
        target: "triptych",
        subheader: "caption",
        flatField: true,
        formField: [
            {
                target: "image",
                formField: ImageField,
                header: "Card Image",
            },
            {
                target: "caption",
                formField: InputField,
                header: "Caption",
                maxLength: 80,
            },
        ],
        header: "Triptych",
        aspectRatio: 0.5625,
    },
    grid: {
        target: "grid",
        subheader: "caption",
        flatField: true,
        formField: [
            {
                target: "image",
                formField: ImageField,
                header: "Card Image",
            },
            {
                target: "caption",
                formField: InputField,
                header: "Caption",
                maxLength: 80,
            },
        ],
        header: "Grid",
        aspectRatio: 0.5625,
    },
    cards: {
        target: "cards",
        header: "Image Cards",
        subheader: "caption",
        flatField: true,
        formField: [
            {
                target: "image",
                formField: ImageField,
                header: "Card Image",
            },
            {
                target: "caption",
                formField: InputField,
                header: "Caption",
                maxLength: 80,
            },
        ],
    },
    video: {
        target: "video",
        formField: InputField,
        header: "Video",
        help: "Please add a YouTube/Vimeo/Video Server Link",
    },
    question: {
        target: "question",
        formField: HTMLField,
        header: "Question",
        maxLength: 120,
    },
    feedback: {
        target: "feedback",
        flatField: true,
        formField: FeedbackField,
        header: "Feedback for the question",
        help: "Please provide feedback for correct and incorrect reponses. Leave them blank to not provide feedback",
        maxLength: 80,
    },
    position: {
        target: "position",
        flatField: true,
        formField: SelectField,
        options: _.map(
            [
                "top left",
                "top right",
                "center center",
                "bottom left",
                "bottom right",
            ],
            (pos) => ({
                key: pos,
                text: _.startCase(_.toLower(pos)),
                value: pos,
            })
        ),
        header: "Text Position",
        help: "Select the position for the text block",
    },
    rebullets: {
        target: "rebullets",
        flatField: true,
        header: "Bullet List",
        formField: [InputField],
        maxLength: 50,
    },
    bullets: {
        target: "bullets",
        flatField: true,
        header: "Bullet List",
        formField: [InputField],
        maxLength: 50,
    },
    iconlist: {
        target: "iconlist",
        header: "Icon Bullet List",
        subheader: "text",
        formField: [
            {
                target: "image",
                formField: ImageField,
                header: "Icon",
                aspectRatio: 1,
                width: "50%",
            },
            {
                target: "text",
                formField: InputField,
                header: "Caption",
                maxLength: 80,
                width: "50%",
            },
        ],
        maxLength: 50,
    },
    link: {
        target: "link",
        header: "External Link",
        formField: ButtonField,
    },
    internal_link: {
        target: "link",
        header: "Internal Link",
        help: "Enter the Button text and the slide number to link to",
        formField: ButtonField,
    },
    conversation: {
        target: "conversation",
        header: "Conversation",
        formField: [InputField],
    },
    brancher: {
        target: "brancher",
        header: "Brancher",
        help: "Give the slide number to navigate to",
        formField: [
            {
                target: "text",
                formField: InputField,
                header: "Button Text",
                maxLength: 80,
                width: "50%",
            },
            {
                target: "slideLink",
                formField: InputField,
                header: "Slide Target",
                width: "50%",
            },
        ],
    },
    // For any of the interactive formats here, if you make any change please look at SlideBuilder for the filtering
    //=============================
    options: {
        target: "options",
        header: "Options",
        help: "Give the options to select from",
        subheader: "text",
        flatField: true,
        radioField: true,
        formField: [RadioField],
        maxLength: 30,
    },
    imageOpts: {
        target: "imageOpts",
        header: "Options",
        help: "Give the options to select from",
        subheader: "image",
        flatField: true,
        radioField: true,
        formField: [RadioImgField],
    },
    choice: {
        target: "choice",
        header: "Choice",
        help: "Give the options to select from",
        subheader: "text",
        flatField: true,
        radioField: true,
        count: 2,
        formField: [RadioField],
        maxLength: 30,
    },
    multiselect: {
        target: "multiselect",
        header: "Multiple Select",
        help: "Give the options to select from",
        subheader: "text",
        flatField: true,
        formField: [CheckField],
        maxLength: 30,
    },
    answer: {
        target: "answer",
        formField: InputField,
        header: "Answer",
        help: "Give the answer to the question",
        maxLength: 15,
    },
};
